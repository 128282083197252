import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";

import SelectingDashboardPreviewWidget from "./SelectingDashboardPreviewWidget";
import Utils from "../lib/Utils";

const ReactGridLayout = WidthProvider(RGL);

export default class DashboardPreviewGraphic extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isWidthReady: false };
  }

  async componentDidMount() {
    // NOTE: Please don't copy this _isMounted anti-pattern! This fixes old code.
    this._isMounted = true;

    const defaultLayout = await this.getDefaultLayout();
    if (this._isMounted) {
      this.setState({ defaultLayout });
    }

    // give page time to size
    await Utils.sleep(200);
    if (this._isMounted) {
      this.setState({ isWidthReady: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { defaultLayout, isWidthReady } = this.state;
    if (!defaultLayout || !isWidthReady) {
      return <div className="vl-dashboard-preview-graphic" />;
    }

    const gridLayout = defaultLayout.getStaticGridLayout();

    const style = this.getBackgroundImageStyle();

    return (
      <ReactGridLayout
        className="vl-dashboard-preview-graphic"
        layout={gridLayout}
        cols={6}
        rowHeight={220 / 4}
        margin={[0, 0]}
        // disable initial layout animation
        measureBeforeMount={true}
        style={style}
      >
        {this.generateDOM(defaultLayout)}
      </ReactGridLayout>
    );
  }

  generateDOM(layout) {
    const gridLayout = layout.get("grid_layout");
    const widgetData = layout.get("widget_data");

    return gridLayout.map((item) => {
      const type = widgetData[item.i].type;
      return (
        <SelectingDashboardPreviewWidget
          key={item.i}
          index={item.i}
          type={type}
        />
      );
    });
  }

  async getDefaultLayout() {
    const { dashboard } = this.props;
    if (!dashboard) {
      return null;
    }

    await dashboard.fetch();
    return await dashboard.getDefaultLayout();
  }

  getBackgroundImageStyle = () => {
    const { dashboard } = this.props;
    if (!dashboard) return null;
    const url = dashboard.get("background_image_url");
    if (!url) return null;

    return {
      backgroundImage: `url("${url}")`,
      backgroundSize: "cover"
    };
  };
}
