import React from "react";
import Clock from "react-live-clock";
import { timezones } from "react-timezone";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class WorldClockViewWidget extends React.Component {
  static defaultProps = {
    config: DashboardWidgetTypes.Types.WorldClock.DefaultState,
  };

  render() {
    const { className, config, layout, ...other } = this.props;
    const fullClassName = `dashboard-widget world-clock ${className}`;

    const {
      timezone,
      isTwelveHour,
      isSecondsDisplayed,
      timezoneText,
      backgroundColor,
      textColor,
      textFontSize,
      textFontWeight,
      textFontStyle,
      opacity,
    } = config;

    const format = this.timeFormat(isTwelveHour, isSecondsDisplayed);
    const label = this.timezoneLabel(timezone, timezoneText);

    function containerStyle() {
      var style = { ...other.style, overflow: "hidden" };

      if (backgroundColor) {
        style = { ...style, backgroundColor: backgroundColor };
      }

      if (opacity) {
        style = { ...style, opacity: opacity / 100 };
      }

      return style;
    }

    function textStyle() {
      var style = { whiteSpace: "nowrap" };

      if (layout.w === 1 && isTwelveHour && isSecondsDisplayed) {
        style = {
          ...style,
          fontSize: "3.0em",
        };
      }

      if (textColor) {
        style = {
          ...style,
          color: textColor,
        };
      }

      if (textFontSize) {
        style = {
          ...style,
          fontSize: textFontSize,
        };
      }

      if (textFontWeight) {
        style = {
          ...style,
          fontWeight: textFontWeight,
        };
      }

      if (textFontStyle) {
        style = {
          ...style,
          fontStyle: textFontStyle,
        };
      }

      return style;
    }

    function timezoneTextStyle() {
      const stringLengthBaseline = 25;
      var style = { whiteSpace: "nowrap" };

      if (layout.w === 1 && label.length > stringLengthBaseline) {
        style = {
          ...style,
          fontSize: `${stringLengthBaseline / label.length}em`,
        };
      }

      if (textColor) {
        style = {
          ...style,
          color: textColor,
        };
      }

      if (textFontSize) {
        style = {
          ...style,
          fontSize: textFontSize,
        };
      }

      if (textFontWeight) {
        style = {
          ...style,
          fontWeight: textFontWeight,
        };
      }

      if (textFontStyle) {
        style = {
          ...style,
          fontStyle: textFontStyle,
        };
      }

      return style;
    }

    return (
      <div className={fullClassName} {...other} style={containerStyle()}>
        <Clock
          format={format}
          ticking={true}
          timezone={timezone}
          style={textStyle()}
        />
        <div className="timezone" style={timezoneTextStyle()}>
          {label}
        </div>
      </div>
    );
  }

  // private

  timeFormat(isTwelveHour, isSecondsDisplayed) {
    if (!!isTwelveHour) {
      if (!!isSecondsDisplayed) return "h:mm:ss A";
      else return "h:mm A";
    } else {
      if (!!isSecondsDisplayed) return "H:mm:ss";
      else return "H:mm";
    }
  }

  timezoneLabel(timezone, timezoneText) {
    if (
      timezoneText !== undefined &&
      typeof timezoneText === "string" &&
      timezoneText.trim() !== ""
    ) {
      return timezoneText;
    } else {
      return timezones.find((tz) => tz.name === timezone).label;
    }
  }
}
