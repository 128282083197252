import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AbstractBasePage from "./AbstractBasePage";
import MasterClientNavigator from "./MasterClientNavigator";

import LayoutEditor from "../components/LayoutEditor";
import LayoutForm from "../components/LayoutForm";
import NullLink from "../components/NullLink";
import LayoutPageTitle from "../components/LayoutPageTitle";
import { BreadcrumbItem } from "../components/Breadcrumbs";
import { Spinner } from "../views/_shared/Bundle";
import PageNotFound from "./PageNotFoundPage";

import {
  loadDashboard,
  loadLayout,
  saveLayout,
  setBreadcrumbItems,
} from "../actions";

class LayoutEditPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  handleSubmit = async (data, { setSubmitting }) => {
    let { layout } = this.props;
    if (!layout) {
      return;
    }

    layout.set(data);
    this.props.saveLayout(layout, { onDone: () => setSubmitting(false) });
  };

  componentWillMount() {
    const { dashboardId, id } = this.props.match.params;
    this.props.loadDashboard(dashboardId);
    this.props.loadLayout(id);
  }

  render() {
    const { dashboard, layout, error } = this.props;

    if (error && error.code === 404) {
      return <PageNotFound />;
    }

    if (!layout) {
      return <Spinner />;
    }

    return (
      <div>
        <LayoutPageTitle dashboard={dashboard} layout={layout} />

        <ul
          uk-tab=""
          data-uk-switcher="{swiping:false}"
          id="dashboardLayoutTabs"
        >
          <li className="uk-active">
            <NullLink>Layout</NullLink>
          </li>
          <li>
            <NullLink>Edit Details</NullLink>
          </li>
        </ul>

        <ul className="uk-switcher">
          <li>
            <LayoutEditor />
          </li>
          <li>
            <LayoutForm layout={layout} onSubmit={this.handleSubmit} />
          </li>
        </ul>
      </div>
    );
  }

  getBreadcrumbItems() {
    const { layout, dashboard } = this.props;
    if (!layout) {
      return [];
    }

    const pushDashboardIndex = this.navigator.pushDashboardIndex;
    const pushDashboardEdit = this.navigator.pushDashboardEdit.bind(
      this.navigator,
      dashboard
    );
    return [
      new BreadcrumbItem("Dashboards", pushDashboardIndex),
      new BreadcrumbItem(dashboard.getDisplayName(), pushDashboardEdit),
      new BreadcrumbItem(layout.getDisplayName()),
    ];
  }
}

function mapStateToProps(state) {
  const { dashboard, layout, breadcrumbItems, error } = state;
  return { dashboard, layout, breadcrumbItems, error };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDashboard: (id) => dispatch(loadDashboard(id)),
    loadLayout: (id) => dispatch(loadLayout(id)),
    saveLayout: (layout, { onDone }) =>
      dispatch(saveLayout(layout, { onDone })),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutEditPage)
);
