import React from "react";
import { withRouter } from "react-router-dom";

import AppNavigator from "./AppNavigator";
import ButtonLink from "../components/ButtonLink";

class PageNotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new AppNavigator(this.props.history);
  }

  render() {
    const pushRoot = this.navigator.pushRoot;
    return (
      <div style={{ backgroundColor: "transparent", textAlign: "center" }}>
        <h2 style={{ fontWeight: "600" }}>Page Not Found</h2>
        <p>Requested page could not be found, please navigate back to the home page.</p>
        <ButtonLink onClick={pushRoot} style={{ cursor: "pointer", textDecoration: "none" }}>
          Go home
        </ButtonLink>
      </div>
    );
  }
}
export default withRouter(PageNotFoundPage);
