import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import { Formik, Field } from "formik";
import { loadThemes } from "../actions";
import ImageUploadField from "./ImageUploadField";
import { Spinner } from "../views/_shared/Bundle";
import $ from "jquery";
import "select2";
import { DashboardsNavigator } from "../views/dashboards/Bundle";

class DashboardForm extends React.Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.navigator = new DashboardsNavigator(this.props.history);
  }

  componentDidMount() {
    this.props.loadThemes();
  }

  componentDidUpdate(previousProps) {
    const previousId =
      previousProps.dashboard && previousProps.dashboard.get("id");
    const currentId = this.props.dashboard && this.props.dashboard.get("id");

    if (previousId !== currentId) {
      if (this.formik.current) {
        this.formik.current.resetForm();
      }
    }

    $(".select2")
      .select2()
      .on("select2:open", function (e) {
        $(".select2-search__field").prop("type", "text");
      });
  }

  handleCancel = () => {
    if (document.getElementById("switcher-dashboard")) {
      UIkit.tab("#switcher-dashboard").show(0);
    } else {
      this.navigator.pushIndex();
    }
  };

  render() {
    const { dashboard, themes, themeDefaults, onSubmit } = this.props;
    if (!dashboard || !themes || !themeDefaults) {
      return <Spinner />;
    }

    const {
      name,
      description,
      background_image_url,
      theme,
    } = dashboard.attributes;
    const initialValues = {
      name,
      description,
      background_image_url,
      theme_id: (theme || themeDefaults).get("id"),
    };

    return (
      <Formik
        ref={this.formik}
        initialValues={initialValues}
        validate={() => {}}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.description &&
            errors.description &&
            touched.background_image_url &&
            errors.background_image_url;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              {error ? (
                <div className="uk-margin uk-form-danger">{error}</div>
              ) : null}

              <div className="uk-margin">
                <label className="uk-form-label">
                  Name <span className="vl-required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="name"
                    type="text"
                    placeholder=""
                    value={values.name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Description</label>
                <div className="uk-form-controls">
                  <Field
                    component="textarea"
                    className="uk-textarea"
                    name="description"
                    type="text"
                    placeholder=""
                    value={values.description || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Theme</label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select select2"
                    name="theme_id"
                    id="theme_id"
                    value={values.theme_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {themes.map((theme) => (
                      <option key={theme.get("id")} value={theme.get("id")}>
                        {theme.get("name")}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Background Image</label>
                <ImageUploadField
                  name="background_image_url"
                  value={values.background_image_url}
                />
              </div>

              <hr />

              <div className="uk-margin uk-flex uk-flex-between">
                <button
                  className="tm-button tm-button-link-caution"
                  type="button"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="tm-button tm-button-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  const { themes, themeDefaults } = state;
  return { themes, themeDefaults };
}

function mapDispatchToProps(dispatch) {
  return { loadThemes: () => dispatch(loadThemes()) };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardForm)
);
