import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import UserAuth from "../../lib/UserAuth";
import MasterClientNavigator from "../../pages/MasterClientNavigator";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isAdmin: false };
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  async componentDidMount() {
    this._isMounted = true;

    const isAdmin = await UserAuth.isCurrentUserAdmin();
    if (this._isMounted) {
      this.setState({ isAdmin });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { isAdmin } = this.state;

    return (
      <div className="tm-sidebar-left uk-visible@m">
        <div className="logo">
          <span className="apple-music">&#xF8FF; Music</span>{" "}
          <span className="radio">Radio</span>
        </div>
        <ul
          className="uk-nav-default uk-nav-parent-icon uk-nav tm-nav tm-primary-nav"
          uk-nav=""
        >
          <li>
            <NavLink
              to={this.navigator.dashboardIndexPath()}
              activeClassName="vl-active"
              isActive={(_match, location) => {
                return (
                  location.pathname.match(/^\/(dashboards|layouts)/) ||
                  location.pathname === "/"
                );
              }}
            >
              Dashboards
            </NavLink>
          </li>
          <li>
            <NavLink
              to={this.navigator.twitterFeedIndexPath()}
              activeClassName="vl-active"
              isActive={(_match, location) => {
                return location.pathname.match(
                  /^\/social\/(twitter-feeds|twitter-global-filter)/
                );
              }}
            >
              Social Feeds
            </NavLink>
          </li>
          <li>
            <NavLink
              to={this.navigator.twitterAnalyticsPath()}
              activeClassName="vl-active"
              isActive={(_match, location) => {
                return location.pathname.match(/^\/social\/twitter-analytics/);
              }}
            >
              Twitter Analytics
            </NavLink>
          </li>
          <li>
            <NavLink
              to={this.navigator.themePath()}
              activeClassName="vl-active"
              isActive={(_match, location) => {
                return location.pathname.match(/^\/themes/);
              }}
            >
              Themes
            </NavLink>
          </li>

          <hr
            className="uk-margin-small-top uk-margin-small-bottom"
            hidden={!isAdmin}
          />

          <li>
            <NavLink
              to={this.navigator.userIndexPath()}
              activeClassName="vl-active"
              isActive={(_match, location) => {
                return location.pathname.match(/^\/admin\/users/);
              }}
              hidden={!isAdmin}
            >
              Users
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}
export default withRouter(Sidebar);
