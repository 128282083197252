import React from "react";
import { connect } from "react-redux";

import $ from "jquery";
import UIkit from "uikit";

import ModalFrame from "./ModalFrame";
import { clearModal } from "../actions";

class Modal extends React.Component {
  componentDidUpdate(oldProps) {
    const wasShowing = this.shouldShow(oldProps);
    const isShowing = this.shouldShow(this.props);
    const { onModalShow, onModalHide } = this.props;

    const modal = UIkit.modal(this.hashModalId());
    if (wasShowing && !isShowing) {
      modal.hide();
      if (onModalHide) {
        onModalHide();
      }
    } else if (!wasShowing && isShowing) {
      modal.show();
      if (onModalShow) {
        onModalShow();
      }
    }
  }

  componentDidMount() {
    $(this.hashModalId()).on({
      "hidden.uk.modal": this.clearTargetModal,
    });
  }

  clearTargetModal = (event) => {
    const { modalId, showModalId } = this.props;
    if (showModalId !== modalId) return;
    if (event.target.id !== modalId) return;

    const { clearModal } = this.props;
    clearModal();
  };

  render() {
    const { modalId, title, children } = this.props;

    if (!this.shouldShow(this.props)) {
      // UIkit needs id in DOM for modal to work; include empty modal
      return <ModalFrame modalId={modalId} />;
    }

    return (
      <ModalFrame modalId={modalId} title={title}>
        {children}
      </ModalFrame>
    );
  }

  shouldShow(props) {
    const { modalId, showModalId } = props;
    return modalId === showModalId;
  }

  hashModalId() {
    const { modalId } = this.props;
    return `#${modalId}`;
  }
}

function mapStateToProps(state) {
  const { showModalId } = state;
  return { showModalId };
}

function mapDispatchToProps(dispatch) {
  return { clearModal: () => dispatch(clearModal()) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
