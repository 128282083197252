import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AbstractBasePage from "./AbstractBasePage";
import MasterClientNavigator from "./MasterClientNavigator";

import ThemeForm from "../components/ThemeForm";
import PageTitle from "../components/PageTitle";
import { BreadcrumbItem } from "../components/Breadcrumbs";
import { Spinner } from "../views/_shared/Bundle";
import { loadTheme, saveTheme, setBreadcrumbItems } from "../actions";
import PageNotFound from "./PageNotFoundPage";

class ThemeEditPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  handleSubmit = async (data, { setSubmitting }) => {
    const { theme } = this.props;
    if (!theme) {
      return;
    }

    theme.set(data);
    const self = this;
    this.props.saveTheme(theme, {
      onSuccess: () => {
        setSubmitting(false);
        self.navigator.pushThemeIndex();
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  componentWillMount() {
    this.loadTheme();
  }

  render() {
    const { theme, error } = this.props;
    
    if (error && error.code === 404) {
      return <PageNotFound />;
    }
    
    let themeRender;

    if (!theme) {
      themeRender = <Spinner />;
    } else {
      themeRender = (
        <>
          <PageTitle text={theme.get("name")} />
          <ThemeForm theme={theme} onSubmit={this.handleSubmit} />
        </>
      );
    }

    return <>{themeRender}</>;
  }

  getBreadcrumbItems() {
    const { theme } = this.props;
    if (!theme) {
      return [];
    }

    const pushThemeIndex = this.navigator.pushThemeIndex;
    return [
      new BreadcrumbItem("Themes", pushThemeIndex),
      new BreadcrumbItem(theme.get("name")),
    ];
  }

  loadTheme() {
    const { id } = this.props.match.params;
    this.props.loadTheme(id);
  }
}

function mapStateToProps(state) {
  const { theme, breadcrumbItems, error } = state;
  return { theme, breadcrumbItems, error };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTheme: (id) => dispatch(loadTheme(id)),
    saveTheme: (theme, { onSuccess, onError }) =>
      dispatch(saveTheme(theme, { onSuccess, onError })),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ThemeEditPage)
);
