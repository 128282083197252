import DashboardCollection from "./models/DashboardCollection";
import DashboardModel from "./models/DashboardModel";
import LayoutModel from "./models/LayoutModel";
import ThemeModel from "./models/ThemeModel";
import TwitterFeedCollection from "./models/TwitterFeedCollection";
import TwitterFeedModel from "./models/TwitterFeedModel";
import ThemeCollection from "./models/ThemeCollection";

import AppAPI from "./lib/AppAPI";
import DashboardWidgetTypes from "./lib/DashboardWidgetTypes";
import Utils from "./lib/Utils";
import API from "./models/API";
import exampleInstagramComments from "./components/exampleInstagramComments";
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

/*
 * action types
 */

export const LOAD_MODEL_DEFAULTS_SUCCESS = "LOAD_MODEL_DEFAULTS_SUCCESS";
export const LOAD_MODEL_DEFAULTS_ERROR = "LOAD_MODEL_DEFAULTS_ERROR";

// dashboards
export const LOAD_DASHBOARDS_SUCCESS = "LOAD_DASHBOARDS_SUCCESS";
export const LOAD_DASHBOARDS_ERROR = "LOAD_DASHBOARDS_ERROR";

export const LOAD_DASHBOARD_SUCCESS = "LOAD_DASHBOARD_SUCCESS";
export const LOAD_DASHBOARD_ERROR = "LOAD_DASHBOARD_ERROR";

export const SAVE_DASHBOARD_SUCCESS = "SAVE_DASHBOARD_SUCCESS";
export const SAVE_DASHBOARD_ERROR = "SAVE_DASHBOARD_ERROR";

export const DESTROY_DASHBOARD_SUCCESS = "DESTROY_DASHBOARD_SUCCESS";
export const DESTROY_DASHBOARD_ERROR = "DESTROY_DASHBOARD_ERROR";

// layouts
export const LOAD_LAYOUT_SUCCESS = "LOAD_LAYOUT_SUCCESS";
export const LOAD_LAYOUT_ERROR = "LOAD_LAYOUT_ERROR";

export const SAVE_LAYOUT_SUCCESS = "SAVE_LAYOUT_SUCCESS";
export const SAVE_LAYOUT_ERROR = "SAVE_LAYOUT_ERROR";

export const DESTROY_LAYOUT_SUCCESS = "DESTROY_LAYOUT_SUCCESS";
export const DESTROY_LAYOUT_ERROR = "DESTROY_LAYOUT_ERROR";

// widgets
export const EDIT_WIDGET = "EDIT_WIDGET";
export const CLEAR_WIDGET = "CLEAR_WIDGET";

export const ADD_SUBWIDGET = "ADD_SUBWIDGET";
export const REMOVE_SUBWIDGET = "REMOVE_SUBWIDGET";

// schedules
export const SCHEDULE_TICKED = "SCHEDULE_TICKED";

export const LOAD_TIME_SLOTS_SUCCESS = "LOAD_TIME_SLOTS_SUCCESS";
export const LOAD_TIME_SLOTS_ERROR = "LOAD_TIME_SLOTS_ERROR";

export const DESTROY_TIME_SLOT_SUCCESS = "DESTROY_TIME_SLOT_SUCCESS";
export const DESTROY_TIME_SLOT_ERROR = "DESTROY_TIME_SLOT_ERROR";

export const EDIT_TIME_SLOT = "EDIT_TIME_SLOT";
export const CLEAR_TIME_SLOT = "CLEAR_TIME_SLOT";

export const SAVE_TIME_SLOT_SUCCESS = "SAVE_TIME_SLOT_SUCCESS";
export const SAVE_TIME_SLOT_ERROR = "SAVE_TIME_SLOT_ERROR";

// social feeds
export const LOAD_TWITTER_FEEDS_SUCCESS = "LOAD_TWITTER_FEEDS_SUCCESS";
export const LOAD_TWITTER_FEEDS_ERROR = "LOAD_TWITTER_FEEDS_ERROR";

export const LOAD_TWITTER_FEED_SUCCESS = "LOAD_TWITTER_FEED_SUCCESS";
export const LOAD_TWITTER_FEED_ERROR = "LOAD_TWITTER_FEED_ERROR";

export const SAVE_TWITTER_FEED_SUCCESS = "SAVE_TWITTER_FEED_SUCCESS";
export const SAVE_TWITTER_FEED_ERROR = "SAVE_TWITTER_FEED_ERROR";

export const DESTROY_TWITTER_FEED_SUCCESS = "DESTROY_TWITTER_FEED_SUCCESS";
export const DESTROY_TWITTER_FEED_ERROR = "DESTROY_TWITTER_FEED_ERROR";

export const SUBSCRIBE_TWITTER_FEED_SUCCESS = "SUBSCRIBE_TWITTER_FEED_SUCCESS";
export const SUBSCRIBE_TWITTER_FEED_ERROR = "SUBSCRIBE_TWITTER_FEED_ERROR";

export const SET_TWITTER_FEED_TWEETS = "SET_TWITTER_FEED_TWEETS";

export const LOAD_TWITTER_LISTS_SUCCESS = "LOAD_TWITTER_LISTS_SUCCESS";
export const LOAD_TWITTER_LISTS_ERROR = "LOAD_TWITTER_LISTS_ERROR";

export const LOAD_TWITTER_GLOBAL_FILTER_SUCCESS =
  "LOAD_TWITTER_GLOBAL_FILTER_SUCCESS";
export const LOAD_TWITTER_GLOBAL_FILTER_ERROR =
  "LOAD_TWITTER_GLOBAL_FILTER_ERROR";

export const SAVE_TWITTER_GLOBAL_FILTER_SUCCESS =
  "SAVE_TWITTER_GLOBAL_FILTER_SUCCESS";
export const SAVE_TWITTER_GLOBAL_FILTER_ERROR =
  "SAVE_TWITTER_GLOBAL_FILTER_ERROR";

// youtube live chat
export const LOAD_YOUTUBE_STREAMER_EMAILS_SUCCESS =
  "LOAD_YOUTUBE_STREAMER_EMAILS_SUCCESS";
export const LOAD_YOUTUBE_STREAMER_EMAILS_ERROR =
  "LOAD_YOUTUBE_STREAMER_EMAILS_ERROR";

export const SUBSCRIBE_YOUTUBE_LIVE_CHAT = "SUBSCRIBE_YOUTUBE_LIVE_CHAT";
export const SET_YOUTUBE_LIVE_CHAT_MESSAGES = "SET_YOUTUBE_LIVE_CHAT_MESSAGES";

// instagram comments
export const SET_INSTAGRAM_COMMENTS = "SET_INSTAGRAM_COMMENTS";

// users
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_ERROR = "LOAD_USERS_ERROR";

export const DESTROY_USER_SUCCESS = "DESTROY_USER_SUCCESS";
export const DESTROY_USER_ERROR = "DESTROY_USER_ERROR";

export const GRANT_USER_ADMIN_ERROR = "GRANT_USER_ADMIN_ERROR";
export const REVOKE_USER_ADMIN_ERROR = "REVOKE_USER_ADMIN_ERROR";

// other
export const SET_BREADCRUMB_ITEMS = "SET_BREADCRUMB_ITEMS";
export const CLEAR_MODAL = "CLEAR_MODAL";

export const POLL_NOW_PLAYING_TRACK = "POLL_NOW_PLAYING_TRACK";
export const POLLED_NOW_PLAYING_TRACK = "POLLED_NOW_PLAYING_TRACK";

export const POLL_UPCOMING_SHOWS = "POLL_UPCOMING_SHOWS";
export const POLLED_UPCOMING_SHOWS = "POLLED_UPCOMING_SHOWS";

export const SAVE_THEME_SUCCESS = "SAVE_THEME_SUCCESS";
export const SAVE_THEME_ERROR = "SAVE_THEME_ERROR";

export const LOAD_THEME_SUCCESS = "LOAD_THEME_SUCCESS";
export const LOAD_THEME_ERROR = "LOAD_THEME_ERROR";

export const LOAD_THEMES_SUCCESS = "LOAD_THEMES_SUCCESS";
export const LOAD_THEMES_ERROR = "LOAD_THEMES_ERROR";

export const DESTROY_THEME_SUCCESS = "DESTROY_THEME_SUCCESS";
export const DESTROY_THEME_ERROR = "DESTROY_THEME_ERROR";

export const POLLED_TWITTER_ANALYTICS_SUCCESS =
  "POLLED_TWITTER_ANALYTICS_SUCCESS";
export const POLLED_TWITTER_ANALYTICS_ERROR = "POLLED_TWITTER_ANALYTICS_ERROR";

/*
 * helpers
 */

let debugEnabled = false;
export function debug(obj) {
  if (debugEnabled) console.log(obj);
}

/*
 * action creators
 */

export function loadModelDefaults() {
  debug({ actionCreator: "loadModelDefaults" });
  return async (dispatch) => {
    try {
      const themeJson = await new AppAPI("themes/defaults").fetchJson();
      const themeDefaults = ThemeModel.findOrCreate(themeJson);

      const dashboardJson = await new AppAPI("dashboards/defaults").fetchJson();
      const dashboardDefaults = new DashboardModel(dashboardJson);

      dispatch(loadModelDefaultsSuccess(dashboardDefaults, themeDefaults));
    } catch (error) {
      dispatch(loadModelDefaultsError(error.statusText || error));
    }
  };
}

export function loadModelDefaultsSuccess(dashboardDefaults, themeDefaults) {
  return {
    type: LOAD_MODEL_DEFAULTS_SUCCESS,
    dashboardDefaults,
    themeDefaults,
  };
}

export function loadModelDefaultsError(error) {
  return { type: LOAD_MODEL_DEFAULTS_ERROR, error };
}

export function loadDashboards() {
  debug({ actionCreator: "loadDashboards" });
  return async (dispatch) => {
    try {
      const dashboards = new DashboardCollection();
      await dashboards.fetch();
      dispatch(loadDashboardsSuccess(dashboards));
    } catch (error) {
      dispatch(loadDashboardsError(error.statusText || error));
    }
  };
}

export function loadDashboardsSuccess(dashboards) {
  return { type: LOAD_DASHBOARDS_SUCCESS, dashboards };
}

export function loadDashboardsError(error) {
  return { type: LOAD_DASHBOARDS_ERROR, error };
}

export function loadDashboard(id) {
  debug({ actionCreator: "loadDashboard" });
  return async (dispatch) => {
    try {
      const dashboard = DashboardModel.findOrCreate({ id });
      await dashboard.fetch();
      dispatch(loadDashboardSuccess(dashboard));

      // eh, just do this here to be sure
      dispatch(loadTimeSlots(dashboard));
    } catch (error) {
      dispatch(loadDashboardError(error.statusText || error, error.status));
    }
  };
}

export function loadDashboardSuccess(dashboard) {
  return { type: LOAD_DASHBOARD_SUCCESS, dashboard };
}

export function loadDashboardError(errorMessage, errorCode) {
  return { type: LOAD_DASHBOARD_ERROR, error: { message: errorMessage, code: errorCode } };
}

export function saveDashboard(
  dashboard,
  { onSuccess = () => {}, onError = () => {} } = {
    onSuccess: () => {},
    onError: () => {},
  }
) {
  debug({ actionCreator: "saveDashboard" });
  return async (dispatch) => {
    try {
      await dashboard.save();
      onSuccess();
      dispatch(saveDashboardSuccess(dashboard));
    } catch (error) {
      var errors = null;
      if (error && error.responseJSON) {
        errors = _.map(error.responseJSON, (v, k) => {
          return _.capitalize(`${k} ${v}.`);
        });
      }
      onError();
      dispatch(saveDashboardError((errors && errors.join("\n")) || error.statusText || error));
    }
  };
}

export function saveDashboardSuccess(dashboard) {
  return { type: SAVE_DASHBOARD_SUCCESS, dashboard };
}

export function saveDashboardError(error) {
  return { type: SAVE_DASHBOARD_ERROR, error };
}

export function destroyDashboard(dashboard) {
  debug({ actionCreator: "destroyDashboard" });
  return async (dispatch) => {
    try {
      await dashboard.destroy();
      dispatch(destroyDashboardSuccess());
    } catch (error) {
      dispatch(destroyDashboardError(error.statusText || error));
    }
  };
}

export function destroyDashboardSuccess() {
  debug({ actionCreator: "destroyDashboardSuccess" });
  return async (dispatch) => dispatch(loadDashboards());
}

export function destroyDashboardError(error) {
  return { type: DESTROY_DASHBOARD_ERROR, error };
}

export function loadLayout(id) {
  debug({ actionCreator: "loadLayout" });
  return async (dispatch) => {
    try {
      const layout = LayoutModel.findOrCreate({ id });
      await layout.fetch();
      const dashboard = layout.get("dashboard");
      await dashboard.fetch();
      const theme = dashboard.get("theme");
      await theme.fetch();
      dispatch(loadLayoutSuccess(layout));
    } catch (error) {
      dispatch(loadLayoutError(error.statusText || error, error.status));
    }
  };
}

export function loadLayoutSuccess(layout) {
  return { type: LOAD_LAYOUT_SUCCESS, layout };
}

export function loadLayoutError(errorMessage, errorCode) {
  return { type: LOAD_LAYOUT_ERROR, error: { message: errorMessage, code: errorCode } };
}

export function saveLayout(
  layout,
  { onDone = () => {} } = { onDone: () => {} }
) {
  debug({ actionCreator: "saveLayout" });
  return async (dispatch) => {
    // HACK: avoid wrecking performance serializing every association;
    // HACK: ...this is almost certainly an issue with the backbone config,
    // HACK: ...but the fix isn't clear
    if ('time_slots' in layout.attributes) {
      delete layout.attributes['time_slots'];
    }
    if ('dashboard' in layout.attributes) {
      delete layout.attributes['dashboard'];
    }

    try {
      await layout.save();
      dispatch(saveLayoutSuccess(layout));
    } catch (error) {
      dispatch(saveLayoutError(error.statusText || error));
    }
    onDone();
  };
}

export function saveLayoutSuccess(layout) {
  return { type: SAVE_LAYOUT_SUCCESS, layout };
}

export function saveLayoutError(error) {
  return { type: SAVE_LAYOUT_ERROR, error };
}

export function destroyLayout(layout) {
  debug({ actionCreator: "destroyLayout" });
  return async (dispatch) => {
    try {
      await layout.fetch();
      const dashboard = layout.get("dashboard");
      await layout.destroy();
      dispatch(destroyLayoutSuccess(dashboard.get("id")));
    } catch (error) {
      dispatch(destroyLayoutError(error.statusText || error));
    }
  };
}

export function destroyLayoutSuccess(dashboardId) {
  debug({ actionCreator: "destroyLayoutSuccess" });
  return async (dispatch) => dispatch(loadDashboard(dashboardId));
}

export function destroyLayoutError(error) {
  return { type: DESTROY_DASHBOARD_ERROR, error };
}

export function editWidget(layout, index) {
  const widgetDataItem = layout.get("widget_data")[index];

  let subwidgetData = undefined;
  if (widgetDataItem.type === DashboardWidgetTypes.Types.Rotating.Type) {
    if (widgetDataItem.config) {
      subwidgetData = widgetDataItem.config.subwidgetData;
    }
  }

  return { type: EDIT_WIDGET, layout, index, widgetDataItem, subwidgetData };
}

export function saveWidget(layout, index, widgetDataItem) {
  debug({ actionCreator: "saveWidget" });
  return async (dispatch) => {
    try {
      const widgetData = layout.get("widget_data");
      layout.set("widget_data", { ...widgetData, [index]: widgetDataItem });

      dispatch(saveLayout(layout));
      dispatch(clearWidget());
    } catch (error) {
      dispatch(saveLayoutError(error.statusText || error));
    }
  };
}

export function clearWidget() {
  return { type: CLEAR_WIDGET };
}

export function addSubwidget(subwidgetDataItem) {
  subwidgetDataItem = { ...subwidgetDataItem, id: uuidv4() }
  return { type: ADD_SUBWIDGET, subwidgetDataItem };
}

export function removeSubwidget(subwidgetDataItem) {
  return { type: REMOVE_SUBWIDGET, subwidgetDataItem };
}

export function scheduleDashboard(id) {
  debug({ actionCreator: "scheduleDashboard" });
  return async (dispatch) => {
    try {
      const dashboard = DashboardModel.findOrCreate({ id });
      await dashboard.fetch();
      dispatch(scheduleTick(dashboard));
    } catch (error) {
      dispatch(loadDashboardError(error.statusText || error));
    }
  };
}

export function scheduleTick(dashboard) {
  debug({ actionCreator: "scheduleTick" });
  return async (dispatch) => {
    await dashboard.fetch();
    const layout = await dashboard.getScheduledLayout();
    await layout.fetch();
    dispatch(scheduleTicked(layout));

    await Utils.sleep(10 * 1000);
    dispatch(scheduleTick(dashboard));
  };
}

export function scheduleTicked(scheduledLayout) {
  return { type: SCHEDULE_TICKED, scheduledLayout };
}

export function loadTimeSlots(dashboard) {
  debug({ actionCreator: "loadTimeSlots" });
  return async (dispatch) => {
    try {
      await dashboard.fetch();
      const schedule = dashboard.get("schedule");
      await schedule.fetch();
      const timeSlots = schedule.get("time_slots");
      dispatch(loadTimeSlotsSuccess(timeSlots));
    } catch (error) {
      dispatch(loadTimeSlotsError(error.statusText || error));
    }
  };
}

export function loadTimeSlotsSuccess(timeSlots) {
  return { type: LOAD_TIME_SLOTS_SUCCESS, timeSlots };
}

export function loadTimeSlotsError(error) {
  return { type: LOAD_TIME_SLOTS_ERROR, error };
}

export function destroyTimeSlot(timeSlot) {
  debug({ actionCreator: "destroyTimeSlot" });
  return async (dispatch) => {
    try {
      await timeSlot.fetch();
      const schedule = timeSlot.get("schedule");
      await schedule.fetch();
      const dashboard = schedule.get("dashboard");
      await timeSlot.destroy();

      dispatch(destroyTimeSlotSuccess(dashboard));
    } catch (error) {
      dispatch(destroyTimeSlotError(error.statusText || error));
    }
  };
}

export function destroyTimeSlotSuccess(dashboard) {
  debug({ actionCreator: "destroyTimeSlotSuccess" });
  return async (dispatch) => {
    dispatch(loadTimeSlots(dashboard));
    dispatch(clearTimeSlot());
  };
}

export function destroyTimeSlotError(error) {
  return { type: DESTROY_DASHBOARD_ERROR, error };
}

export function editTimeSlot(timeSlot) {
  return { type: EDIT_TIME_SLOT, timeSlot };
}

export function saveTimeSlot(
  timeSlot,
  { onDone = () => {} } = { onDone: () => {} }
) {
  debug({ actionCreator: "saveTimeSlot" });
  return async (dispatch) => {
    try {
      // HACK: i have no idea why this is necessary but it fixes some
      // HACK: ... issue (maybe infinite recursion?) that causes chrome
      // HACK: ... to segfault with an undocumented error message
      if ('layout' in timeSlot.attributes) {
        if (!('layout_id' in timeSlot.attributes)) {
          const layout_id = timeSlot.get('layout').id;
          timeSlot.set({ layout: null, layout_id });
        }
      }
      if ('schedule' in timeSlot.attributes) {
        if (!('schedule_id' in timeSlot.attributes)) {
          const schedule_id = timeSlot.get('schedule').id;
          timeSlot.set({ schedule: null, schedule_id });
        }
      }

      await timeSlot.save();
      dispatch(saveTimeSlotSuccess(timeSlot));
      dispatch(clearTimeSlot());
    } catch (error) {
      var errors = null;
      if (error && error.responseJSON) {
        errors = _.map(error.responseJSON, (v, k) => {
          return `* ${_.capitalize(k)}: ${v}.`.replace("_", " ");
        });
      }
      dispatch(saveTimeSlotError((errors && errors.join("\n")) || error.statusText || error));

      onDone();
      return;
    }

    try {
      const schedule = timeSlot.get("schedule");
      await schedule.fetch();
      const dashboard = schedule.get("dashboard");
      await dashboard.fetch();
      dispatch(loadTimeSlots(dashboard));
    } catch (error) {
      dispatch(loadTimeSlotsError(error.statusText || error));
    }

    onDone();
  };
}

export function saveTimeSlotSuccess(timeSlot) {
  return { type: SAVE_TIME_SLOT_SUCCESS, timeSlot };
}

export function saveTimeSlotError(error) {
  return { type: SAVE_TIME_SLOT_ERROR, error };
}

export function clearTimeSlot() {
  return { type: CLEAR_TIME_SLOT };
}

export function loadTwitterFeeds() {
  debug({ actionCreator: "loadTwitterFeeds" });
  return async (dispatch) => {
    try {
      const twitterFeeds = new TwitterFeedCollection();
      await twitterFeeds.fetch();
      dispatch(loadTwitterFeedsSuccess(twitterFeeds));
    } catch (error) {
      dispatch(loadTwitterFeedsError(error.statusText || error));
    }
  };
}

export function loadTwitterFeedsSuccess(twitterFeeds) {
  return { type: LOAD_TWITTER_FEEDS_SUCCESS, twitterFeeds };
}

export function loadTwitterFeedsError(error) {
  return { type: LOAD_TWITTER_FEEDS_ERROR, error };
}

export function loadTwitterFeed(id) {
  debug({ actionCreator: "loadTwitterFeed" });
  return async (dispatch) => {
    try {
      const twitterFeed = TwitterFeedModel.findOrCreate({ id });
      await twitterFeed.fetch();
      dispatch(loadTwitterFeedSuccess(twitterFeed));
    } catch (error) {
      dispatch(loadTwitterFeedError(error.statusText || error, error.status));
    }
  };
}

export function loadTwitterFeedSuccess(twitterFeed) {
  return { type: LOAD_TWITTER_FEED_SUCCESS, twitterFeed };
}

export function loadTwitterFeedError(errorMessage, errorCode) {
  return { type: LOAD_TWITTER_FEED_ERROR, error: { message: errorMessage, code: errorCode } };
}

export function saveTwitterFeed(
  twitterFeed,
  { onSuccess = () => {}, onError = (_error) => {} } = {
    onSuccess: () => {},
    onError: (_error) => {},
  }
) {
  debug({ actionCreator: "saveTwitterFeed" });
  return async (dispatch) => {
    try {
      await twitterFeed.save();
      onSuccess();
      dispatch(saveTwitterFeedSuccess(twitterFeed));
    } catch (error) {
      // they need to see this on the form
      const message = error.responseJSON
        ? JSON.stringify(error.responseJSON)
        : error.statusText || error;
      onError(message);

      dispatch(saveTwitterFeedError(error.statusText || error));
    }
  };
}

export function saveTwitterFeedSuccess(twitterFeed) {
  return { type: SAVE_TWITTER_FEED_SUCCESS, twitterFeed };
}

export function saveTwitterFeedError(error) {
  return { type: SAVE_TWITTER_FEED_ERROR, error };
}

export function destroyTwitterFeed(twitterFeed) {
  debug({ actionCreator: "destroyTwitterFeed" });
  return async (dispatch) => {
    try {
      await twitterFeed.destroy();
      dispatch(destroyTwitterFeedSuccess());
    } catch (error) {
      dispatch(destroyTwitterFeedError(error.statusText || error));
    }
  };
}

export function destroyTwitterFeedSuccess() {
  debug({ actionCreator: "destroyTwitterFeedSuccess" });
  return async (dispatch) => dispatch(loadTwitterFeeds());
}

export function destroyTwitterFeedError(error) {
  return { type: DESTROY_TWITTER_FEED_ERROR, error };
}

export function subscribeTwitterFeed(twitterFeedID) {
  debug({ actionCreator: "subscribeTwitterFeed" });
  return async (dispatch) => {
    try {
      const twitterFeed = TwitterFeedModel.findOrCreate({ id: twitterFeedID });
      await twitterFeed.fetch();
      dispatch(subscribeTwitterFeedSuccess(twitterFeed));
    } catch (error) {
      dispatch(subscribeTwitterFeedError(error.statusText || error));
    }
  };
}

export function subscribeTwitterFeedSuccess(twitterFeed) {
  return { type: SUBSCRIBE_TWITTER_FEED_SUCCESS, twitterFeed };
}

export function subscribeTwitterFeedError(error) {
  return { type: SUBSCRIBE_TWITTER_FEED_ERROR, error };
}

export function setTwitterFeedTweets(twitterFeedID, tweets) {
  return { type: SET_TWITTER_FEED_TWEETS, twitterFeedID, tweets };
}

export function loadTwitterLists() {
  debug({ actionCreator: "loadTwitterLists" });
  return async (dispatch) => {
    try {
      const api = new AppAPI("social/twitter_lists");
      const twitterLists = await api.fetchJson();
      dispatch(loadTwitterListsSuccess(twitterLists));
    } catch (error) {
      dispatch(loadTwitterListsError(error.statusText || error));
    }
  };
}

export function loadTwitterListsSuccess(twitterLists) {
  return { type: LOAD_TWITTER_LISTS_SUCCESS, twitterLists };
}

export function loadTwitterListsError(error) {
  return { type: LOAD_TWITTER_LISTS_ERROR, error };
}

export function loadTwitterGlobalFilter() {
  debug({ actionCreator: "loadTwitterGlobalFilter" });
  return async (dispatch) => {
    try {
      const api = new AppAPI("social/twitter_global_filters");
      const twitterGlobalFilters = await api.fetchJson();

      // there is always exactly one
      const twitterGlobalFilter = twitterGlobalFilters[0];
      dispatch(loadTwitterGlobalFilterSuccess(twitterGlobalFilter));
    } catch (error) {
      dispatch(loadTwitterGlobalFilterError(error.statusText || error));
    }
  };
}

export function loadTwitterGlobalFilterSuccess(twitterGlobalFilter) {
  return { type: LOAD_TWITTER_GLOBAL_FILTER_SUCCESS, twitterGlobalFilter };
}

export function loadTwitterGlobalFilterError(error) {
  return { type: LOAD_TWITTER_GLOBAL_FILTER_ERROR, error };
}

export function saveTwitterGlobalFilter(
  twitterGlobalFilter,
  { onSuccess = () => {}, onError = () => {} } = {
    onSuccess: () => {},
    onError: () => {},
  }
) {
  debug({ actionCreator: "saveTwitterGlobalFilter" });
  return async (dispatch) => {
    try {
      const api = new AppAPI("social/twitter_global_filters");
      const updatedGlobalFilter = await api.fetchJson({
        id: twitterGlobalFilter.id,
        method: "PUT",
        body: { twitter_global_filter: twitterGlobalFilter },
      });
      dispatch(saveTwitterGlobalFilterSuccess(updatedGlobalFilter));
      onSuccess();
    } catch (error) {
      dispatch(saveTwitterGlobalFilterError(error.statusText || error));
      onError();
    }
  };
}

export function saveTwitterGlobalFilterSuccess(twitterGlobalFilter) {
  return { type: SAVE_TWITTER_GLOBAL_FILTER_SUCCESS, twitterGlobalFilter };
}

export function saveTwitterGlobalFilterError(error) {
  return { type: SAVE_TWITTER_GLOBAL_FILTER_ERROR, error };
}

export function loadYouTubeStreamerEmails() {
  debug({ actionCreator: "loadYouTubeStreamerEmails" });
  return async (dispatch) => {
    try {
      const api = new AppAPI("social/youtube_streamer_emails");
      const response = await api.get();
      const youtubeStreamerEmails = await response.json();
      dispatch(loadYouTubeStreamerEmailsSuccess(youtubeStreamerEmails));
    } catch (error) {
      dispatch(loadYouTubeStreamerEmailsError(error.statusText || error));
    }
  };
}

export function loadYouTubeStreamerEmailsSuccess(youtubeStreamerEmails) {
  return { type: LOAD_YOUTUBE_STREAMER_EMAILS_SUCCESS, youtubeStreamerEmails };
}

export function loadYouTubeStreamerEmailsError(error) {
  return { type: LOAD_YOUTUBE_STREAMER_EMAILS_ERROR, error };
}

export function subscribeYouTubeLiveChat(streamerEmail) {
  return { type: SUBSCRIBE_YOUTUBE_LIVE_CHAT, streamerEmail };
}

export function setYouTubeLiveChatMessages(streamerEmail, chatMessages) {
  return { type: SET_YOUTUBE_LIVE_CHAT_MESSAGES, streamerEmail, chatMessages };
}

export function setInstagramComments(instagramComments) {
  return { type: SET_INSTAGRAM_COMMENTS, instagramComments };
}

export function addInstagramComment(instagramComment) {
  debug({ actionCreator: "addInstagramComment" });
  return async (dispatch, getState) => {
    const { instagramComments } = getState();
    dispatch(setInstagramComments([instagramComment, ...instagramComments]));
  };
}

// WARNING: Only use for development!
export function streamMockInstagramComments() {
  debug({ actionCreator: "streamMockInstagramComments" });
  return async (dispatch) => {
    const instagramComment = {
      ...exampleInstagramComments[0],
      id: new Date().getTime().toString(),
    };
    dispatch(addInstagramComment(instagramComment));

    await Utils.sleep(5000);
    dispatch(streamMockInstagramComments());
  };
}

export function loadUsers() {
  debug({ actionCreator: "loadUsers" });
  return async (dispatch) => {
    try {
      const api = new AppAPI("admin/users");
      const response = await api.get();
      const users = await response.json();
      dispatch(loadUsersSuccess(users));
    } catch (error) {
      dispatch(loadUsersError(error.statusText || error));
    }
  };
}

export function loadUsersSuccess(users) {
  return { type: LOAD_USERS_SUCCESS, users };
}

export function loadUsersError(error) {
  return { type: LOAD_USERS_ERROR, error };
}

export function destroyUser(id) {
  debug({ actionCreator: "destroyUser" });
  return async (dispatch) => {
    try {
      const api = new AppAPI("admin/users");
      await api.fetchJson({ id, method: "DELETE", expectJson: false });
      dispatch(loadUsers());
    } catch (error) {
      dispatch(destroyUserError(error.statusText || error));
    }
  };
}

export function destroyUserError(error) {
  return { type: DESTROY_USER_ERROR, error };
}

export function grantUserAdmin(id) {
  debug({ actionCreator: "grantUserAdmin" });
  return async (dispatch) => {
    try {
      const api = new AppAPI("admin/users");
      await api.fetchJson({
        id,
        appendix: "grant_admin",
        method: "PATCH",
        expectJson: false,
      });
      dispatch(loadUsers());
    } catch (error) {
      dispatch(grantUserAdminError(error.statusText || error));
    }
  };
}

export function grantUserAdminError(error) {
  return { type: GRANT_USER_ADMIN_ERROR, error };
}

export function revokeUserAdmin(id) {
  debug({ actionCreator: "revokeUserAdmin" });
  return async (dispatch) => {
    try {
      const api = new AppAPI("admin/users");
      await api.fetchJson({
        id,
        appendix: "revoke_admin",
        method: "PATCH",
        expectJson: false,
      });
      dispatch(loadUsers());
    } catch (error) {
      dispatch(revokeUserAdminError(error.statusText || error));
    }
  };
}

export function revokeUserAdminError(error) {
  return { type: REVOKE_USER_ADMIN_ERROR, error };
}

export function setBreadcrumbItems(breadcrumbItems) {
  debug({ actionCreator: "setBreadcrumbItems", breadcrumbItems });
  return { type: SET_BREADCRUMB_ITEMS, data: breadcrumbItems };
}

export function clearModal() {
  return { type: CLEAR_MODAL };
}

export function pollNowPlayingTrack(url = "") {
  debug({ actionCreator: "pollNowPlayingTrack" });
  return async (dispatch) => {
    try {
      const feedUrl =
        API.url("now_playing") +
        `?url=${encodeURIComponent(
          url
        )}`;

        const response = await fetch(feedUrl);
        const track = await response.json();
        dispatch(polledNowPlayingTrack(track));
    } catch (error) {
      console.log({ error });
      // fall through to wait and retry
    }

    await Utils.sleep(5000);

    dispatch(pollNowPlayingTrack(url));
  };
}

// This version of the action was created for testing with mock song data.
// Keep commented out unless you need it for testing
// export function pollNowPlayingTrack(index = 0) {
//   debug({ actionCreator: 'pollNowPlayingTrack' })
//   return async dispatch => {
//     const track = exampleNowPlayings[index % exampleNowPlayings.length]
//     dispatch(polledNowPlayingTrack(track))

//     await Utils.sleep(5000)

//     dispatch(pollNowPlayingTrack(index + 1))
//   }
// }

export function polledNowPlayingTrack(track) {
  return { type: POLLED_NOW_PLAYING_TRACK, track };
}

export function pollUpcomingShows(url = "") {
  debug({ actionCreator: "pollUpcomingShows" });
  return async (dispatch) => {
    try {
      const feedUrl =
        API.url("upcoming_shows") +
        `?url=${encodeURIComponent(
          url
        )}`;

      const response = await fetch(feedUrl);
      const data = await response.json();
      let { upcomingShows } = data;

      const now = Date.now();
      upcomingShows = upcomingShows.filter((upcomingShow) => {
        let { endTime } = upcomingShow;
        return now < moment(endTime).valueOf();
      });

      dispatch(polledUpcomingShows(upcomingShows));
    } catch (error) {
      console.log({ error });
      // fall through to wait and retry
    }

    await Utils.sleep(1 * 60 * 1000);

    dispatch(pollUpcomingShows(url));
  };
}

export function polledUpcomingShows(upcomingShows) {
  return { type: POLLED_UPCOMING_SHOWS, upcomingShows };
}

export function saveTheme(
  theme,
  { onSuccess = () => {}, onError = () => {} } = {
    onSuccess: () => {},
    onError: () => {},
  }
) {
  debug({ actionCreator: "saveTheme" });
  return async (dispatch) => {
    try {
      await theme.save();
      onSuccess();
      dispatch(saveThemeSuccess(theme));
    } catch (error) {
      onError();

      const message =
        (error.responseJSON && error.responseJSON.input_scss) ||
        (error.responseJSON && JSON.stringify(error.responseJSON, null, 2)) ||
        error.statusText ||
        error;
      dispatch(saveThemeError(message));
    }
  };
}

export function saveThemeSuccess(theme) {
  return { type: SAVE_THEME_SUCCESS, theme };
}

export function saveThemeError(error) {
  return { type: SAVE_THEME_ERROR, error };
}

export function loadTheme(id) {
  debug({ actionCreator: "loadTheme" });
  return async (dispatch) => {
    try {
      const theme = ThemeModel.findOrCreate({ id });
      await theme.fetch();
      dispatch(loadThemeSuccess(theme));
    } catch (error) {
      dispatch(loadThemeError(error.statusText || error, error.status));
    }
  };
}

export function loadThemeSuccess(theme) {
  return { type: LOAD_THEME_SUCCESS, theme };
}

export function loadThemeError(errorMessage, errorCode) {
  return { type: LOAD_THEME_ERROR, error: { message: errorMessage, code: errorCode } };
}

export function loadThemes() {
  debug({ actionCreator: "loadThemes" });
  return async (dispatch) => {
    try {
      const themes = new ThemeCollection();
      await themes.fetch();
      dispatch(loadThemesSuccess(themes));
    } catch (error) {
      dispatch(loadThemesError(error.statusText || error));
    }
  };
}

export function loadThemesSuccess(themes) {
  return { type: LOAD_THEMES_SUCCESS, themes };
}

export function loadThemesError(error) {
  return { type: LOAD_THEMES_ERROR, error };
}

export function destroyTheme(theme) {
  debug({ actionCreator: "destroyTheme" });
  return async (dispatch) => {
    try {
      await theme.destroy();
      dispatch(destroyThemeSuccess());
    } catch (error) {
      dispatch(destroyThemeError(error.statusText || error));
    }
  };
}

export function destroyThemeSuccess() {
  debug({ actionCreator: "destroyThemeSuccess" });
  return async (dispatch) => dispatch(loadThemes());
}

export function destroyThemeError(error) {
  return { type: DESTROY_THEME_ERROR, error };
}

let pollingTwitterAnalytics = false;
export function startPollingTwitterAnalytics() {
  debug({ actionCreator: "startPollingTwitterAnalytics" });
  return async (dispatch) => {
    if (pollingTwitterAnalytics) {
      return;
    }

    pollingTwitterAnalytics = true;
    dispatch(pollTwitterAnalytics());
  };
}

export function stopPollingTwitterAnalytics() {
  debug({ actionCreator: "stopPollingTwitterAnalytics" });
  return async () => {
    pollingTwitterAnalytics = false;
  };
}

export function pollTwitterAnalytics() {
  debug({ actionCreator: "pollTwitterAnalytics" });
  return async (dispatch) => {
    try {
      if (!pollingTwitterAnalytics) {
        return;
      }

      const response = await new AppAPI("social/twitter_analytics").get();
      const twitterAnalytics = await response.json();
      dispatch(polledTwitterAnalyticsSuccess(twitterAnalytics));
    } catch (error) {
      dispatch(polledTwitterAnalyticsError());
      return;
    }

    await Utils.sleep(60 * 1000);

    dispatch(pollTwitterAnalytics());
  };
}

export function polledTwitterAnalyticsSuccess(twitterAnalytics) {
  return { type: POLLED_TWITTER_ANALYTICS_SUCCESS, twitterAnalytics };
}

export function polledTwitterAnalyticsError() {
  return { type: POLLED_TWITTER_ANALYTICS_ERROR };
}
