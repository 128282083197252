import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import AbstractBasePage from "./AbstractBasePage";
import MasterClientNavigator from "./MasterClientNavigator";

import PageTitle from "../components/PageTitle";
import TwitterFeedForm from "../components/TwitterFeedForm";
import ErrorBanner from "../components/ErrorBanner";
import { BreadcrumbItem } from "../components/Breadcrumbs";

import TwitterFormParser from "../lib/TwitterFormParsing";
import PageNotFound from "./PageNotFoundPage";

import {
  loadTwitterFeed,
  saveTwitterFeed,
  setBreadcrumbItems,
} from "../actions";

class TwitterFeedEditPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  handleSubmit = async (values, { setSubmitting }) => {
    const { twitterFeed } = this.props;
    if (!twitterFeed) {
      return;
    }

    const self = this;
    TwitterFormParser.setAttributesFromFormValues(twitterFeed, values);
    this.props.saveTwitterFeed(twitterFeed, {
      onSuccess: () => {
        setSubmitting(false);
        self.navigator.pushTwitterFeedIndex();
        UIkit.notification(
          "<span uk-icon='icon: check'></span> Twitter Feed Saved",
          {
            status: "success",
            timeout: 5000,
          }
        );
      },
      onError: (error) => {
        setSubmitting(false);
        self.setState({ error });
      },
    });
  };

  componentWillMount() {
    this.loadTwitterFeed();
  }

  render() {
    const { twitterFeed, error } = this.props;

    if (error && error.code === 404) {
      return <PageNotFound />;
    }

    if (!twitterFeed) {
      return null;
    }

    return (
      <div>
        <PageTitle text={twitterFeed.getDisplayName()} />
        <TwitterFeedForm
          twitterFeed={twitterFeed}
          onSubmit={this.handleSubmit}
        />
        <ErrorBanner parent={this} />
      </div>
    );
  }

  getBreadcrumbItems() {
    const { twitterFeed } = this.props;
    if (!twitterFeed) {
      return [];
    }

    const pushTwitterFeedIndex = this.navigator.pushTwitterFeedIndex;
    return [
      new BreadcrumbItem("Social Feeds", pushTwitterFeedIndex),
      new BreadcrumbItem("Twitter"),
      new BreadcrumbItem(twitterFeed.getDisplayName()),
    ];
  }

  loadTwitterFeed() {
    const { id } = this.props.match.params;
    this.props.loadTwitterFeed(id);
  }
}

function mapStateToProps(state) {
  const { twitterFeed, breadcrumbItems, error } = state;
  return { twitterFeed, breadcrumbItems, error };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTwitterFeed: (id) => dispatch(loadTwitterFeed(id)),
    saveTwitterFeed: (twitterFeed, { onSuccess, onError }) =>
      dispatch(saveTwitterFeed(twitterFeed, { onSuccess, onError })),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TwitterFeedEditPage)
);
