import React from "react";
import Datetime from "react-datetime";

import { Field } from "formik";

import moment from "moment";

// Support for Formik #598
// https://github.com/YouCanBookMe/react-datetime/issues/598#issuecomment-440518051
class InnerFormikDatetime extends React.Component {
  constructor(props) {
    super(props);
    this.state = { timeConstraints: null };
  }

  onFieldChange = (value) => {
    const { field, form, maxValue, minValue, onDateChange } = this.props;

    if (onDateChange) {
      const updatedValue = value instanceof moment ? value : moment(value);
      const previousValue = moment(field.value);
      onDateChange({
        updatedValue: updatedValue,
        previousValue: previousValue,
        maxValue: maxValue,
        minValue: minValue,
        field: field,
        form: form,
      });
    }
  };

  onFieldBlur = () => {
    const { field, form } = this.props;
    if (!field || !form) {
      return;
    }

    form.setFieldTouched(field.name, true);
  };

  shouldRenderDate = (currentDate, _) => {
    const { disablePastDates } = this.props;

    if (disablePastDates) {
      const yesterday = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .subtract(1, "second");
      return currentDate.isAfter(yesterday);
    }

    return true;
  };

  render() {
    const { field } = this.props;
    if (!field) {
      return null;
    }

    return (
      <Datetime
        name={field.name}
        value={field.value}
        onChange={this.onFieldChange}
        onBlur={this.onFieldBlur}
        inputProps={{ className: "uk-input uk-datetime-input", readOnly: true }}
        isValidDate={this.shouldRenderDate}
      />
    );
  }
}

export default class FormikDatetime extends React.Component {
  render() {
    return <Field {...this.props} component={InnerFormikDatetime} />;
  }
}
