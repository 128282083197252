import React from "react";
import { Redirect } from "react-router-dom";

import LayoutViewPane from "../components/LayoutViewPane";
import TwitterFeedConsumerManager from "../components/TwitterFeedConsumerManager";
import YouTubeLiveChatConsumerManager from "../components/YouTubeLiveChatConsumerManager";
import InstagramCommentsConsumerManager from "../components/InstagramCommentsConsumerManager";

import API from "../models/API";

export default class BaseViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { height: 0 };
  }

  componentWillMount() {
    this.updateHeight();
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  render() {
    const { layout, error } = this.props;
    
    if (error && error.code === 404) {
      return <Redirect to="/404" />;
    }
    
    if (!layout) {
      return null;
    }

    const { height } = this.state;
    const rowHeight = this.calculateRowHeight(height);

    const dashboard = layout.get("dashboard");
    const theme = dashboard.get("theme");

    const style = this.getBackgroundImageStyle(dashboard);
    const url = API.url(`themes/${theme.id}/styles.css`);

    return (
      <div className={`theme-${theme.id}`}>
        <div className="dashboard-view-page noselect" style={style}>
          <link rel="stylesheet" type="text/css" href={url} />

          <TwitterFeedConsumerManager />
          <YouTubeLiveChatConsumerManager />
          <InstagramCommentsConsumerManager />

          {layout ? (
            <LayoutViewPane layout={layout} rowHeight={rowHeight} />
          ) : null}
        </div>
      </div>
    );
  }

  // private

  getBackgroundImageStyle = (dashboard) => {
    const url = dashboard.get("background_image_url");
    if (!url) return null;

    return {
      backgroundImage: `url("${url}")`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    };
  };

  updateHeight = () => {
    this.setState({ height: window.innerHeight });
  };

  calculateRowHeight(viewHeight) {
    const rowCount = 4;

    const marginCount = rowCount + 1;
    const marginHeight = 10 + 1; // +1 for good measure

    return (viewHeight - marginHeight * marginCount) / rowCount;
  }
}
