import React from "react";
import { connect } from "react-redux";

import UIkit from "uikit";

import ButtonLink from "./ButtonLink";
import SubwidgetRow from "./SubwidgetRow";
import WidgetModal from "./WidgetModal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

import { addSubwidget, loadDashboard } from "../actions";

class RotatingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expandedSubwidgetActions: null };
  }

  defaultState = DashboardWidgetTypes.Types.Rotating.DefaultState;

  handleAddSubwidget = (type) => {
    UIkit.dropdown("#subwidget-dropdown").hide();

    const { addSubwidget } = this.props;
    const subwidgetDataItem = { type, config: {} };
    addSubwidget(subwidgetDataItem);
  };

  updateExpandedWidgetState = () => {
    const { expandedSubwidgetActions } = this.state;
    if (expandedSubwidgetActions) {
      const { handleDone } = expandedSubwidgetActions;
      handleDone();
    }
  };

  setExpandedWidgetActions = (subwidgetActions) => {
    this.setState({ expandedSubwidgetActions: subwidgetActions });
  };

  handleSubwidgetExpand = (subwidgetActions) => {
    this.updateExpandedWidgetState();
    this.setExpandedWidgetActions(subwidgetActions);
  };

  handleSubwidgetCollapse = (subwidgetActions) => {
    const { expandedSubwidgetActions } = this.state;
    if (expandedSubwidgetActions) {
      const { subwidgetKey } = expandedSubwidgetActions;
      if (subwidgetKey === subwidgetActions.subwidgetKey) {
        this.setExpandedWidgetActions(null);
      }
    }
  };

  handleSubwidgetDelete = (subwidgetActions) => {
    const { expandedSubwidgetActions } = this.state;
    if (expandedSubwidgetActions) {
      const { handleDone } = expandedSubwidgetActions;
      handleDone();
    }
  };

  handleSubwidgetSubmit = (subwidgetActions) => {
    const { collapse } = subwidgetActions;
    if (collapse) {
      collapse();
    }
  }

  onModalShow = () => {
    this.setExpandedWidgetActions(null);
  }

  onModalHide = () => {
    const { dashboard, loadDashboard } = this.props;
    loadDashboard(dashboard.get("id"));
    this.setExpandedWidgetActions(null);
  }

  render() {
    let { widgetDataItem, subwidgetData } = this.props;
    if (!widgetDataItem) {
      return <WidgetModal modalId="rotating-modal" />;
    }

    const config = this.normalizeConfig(widgetDataItem.config);
    if (!subwidgetData) {
      subwidgetData = config.subwidgetData;
    }

    const subwidgetMenuItems = [
      [
        "Add Text/Image",
        () =>
          this.handleAddSubwidget(DashboardWidgetTypes.Types.TextImage.Type),
      ],
      [
        "Add World Clock",
        () =>
          this.handleAddSubwidget(DashboardWidgetTypes.Types.WorldClock.Type),
      ],
      [
        "Add Now Playing",
        () =>
          this.handleAddSubwidget(DashboardWidgetTypes.Types.NowPlaying.Type),
      ],
      [
        "Add Schedule",
        () =>
          this.handleAddSubwidget(
            DashboardWidgetTypes.Types.RadioSchedule.Type
          ),
      ],
      [
        "Add Twitter Feed",
        () =>
          this.handleAddSubwidget(DashboardWidgetTypes.Types.TwitterFeed.Type),
      ],
    ];

    return (
      <WidgetModal
        modalId="rotating-modal"
        title="Edit Rotating Widget"
        initialValues={config}
        onModalShow={this.onModalShow}
        onModalHide={this.onModalHide}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.name && errors.name && touched.interval && errors.interval;

          return (
            <form
              className="uk-form-stacked"
              onSubmit={(e) => {
                this.updateExpandedWidgetState();
                handleSubmit(e);
              }}
            >
              <div className="uk-modal-body" uk-overflow-auto="">
                <div className="uk-margin">
                  <label className="uk-form-label">Name</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="name"
                      type="text"
                      placeholder=""
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <div>Rotate Every</div>
                    <div>
                      {this.verbalDuration(
                        values.interval || config.interval || 30
                      )}
                    </div>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-range"
                      name="interval"
                      type="range"
                      min={5}
                      max={30 * 60}
                      step={5}
                      value={values.interval || config.interval || 30}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div
                  className="subwidget-rows"
                  style={{ borderRadius: "0px 0px 10px 10px" }}
                >
                  {subwidgetData.map((subwidgetDataItem, index) => (
                    <SubwidgetRow
                      key={subwidgetDataItem.id || index}
                      subwidgetKey={subwidgetDataItem.id || index}
                      subwidgetDataItem={subwidgetDataItem}
                      style={{
                        borderRadius: index === 0 ? "10px 10px 0px 0px" : "0px",
                      }}
                      onExpand={this.handleSubwidgetExpand}
                      onCollapse={this.handleSubwidgetCollapse}
                      onDelete={this.handleSubwidgetDelete}
                      onSubmit={this.handleSubwidgetSubmit}
                    />
                  ))}
                </div>

                <div
                  className="widget-socket uk-padding-small"
                  style={{
                    borderRadius:
                      subwidgetData.length === 0
                        ? "10px 10px"
                        : "0px 0px 10px 10px",
                  }}
                >
                  <div className="inner-socket empty uk-flex">
                    <img
                      className="icon uk-margin-left uk-margin-right"
                      src="/images/add.svg"
                      alt="Add a widget"
                    />
                    <div className="text uk-text uk-text-middle">
                      Add Widget
                    </div>
                  </div>
                </div>
                <div id="subwidget-dropdown" uk-dropdown="mode: click">
                  <ul className="uk-nav">
                    {subwidgetMenuItems.map(([text, onClick]) => (
                      <li key={text}>
                        <ButtonLink onClick={onClick}>{text}</ButtonLink>
                      </li>
                    ))}
                  </ul>
                </div>

                {error ? (
                  <div className="uk-margin uk-form-danger">{error}</div>
                ) : null}

                <div className="uk-margin uk-flex uk-flex-between">
                  <button
                    className="tm-button tm-button-link-caution uk-modal-close"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="tm-button tm-button-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const { name, subwidgetData, interval } = config || {};
    const normalized = { name, subwidgetData, interval };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.subwidgetData === undefined) {
      normalized.subwidgetData = this.defaultState.subwidgetData;
    }
    if (normalized.interval === undefined) {
      normalized.interval = this.defaultState.interval;
    }

    return normalized;
  }

  verbalDuration(seconds) {
    const verbalMinutes = this.verbalMinutes(seconds);
    const verbalSeconds = this.verbalSeconds(seconds);

    if (verbalSeconds === "") {
      return verbalMinutes;
    }
    if (verbalMinutes === "") {
      return verbalSeconds;
    }
    return `${verbalMinutes}, ${verbalSeconds}`;
  }

  verbalMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);
    if (minutes === 0) {
      return "";
    }
    if (minutes === 1) {
      return `${minutes} minute`;
    }
    return `${minutes} minutes`;
  }

  verbalSeconds(seconds) {
    const verbalSeconds = seconds % 60;
    if (verbalSeconds === 0) {
      return "";
    }
    if (verbalSeconds === 1) {
      return `${verbalSeconds} second`;
    }
    return `${verbalSeconds} seconds`;
  }
}

function mapStateToProps(state) {
  const { dashboard, widgetDataItem, subwidgetData } = state;
  return { dashboard, widgetDataItem, subwidgetData };
}

function mapDispatchToProps(dispatch) {
  return {
    addSubwidget: (subwidgetDataItem) =>
      dispatch(addSubwidget(subwidgetDataItem)),
    loadDashboard: (id) => 
      dispatch(loadDashboard(id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RotatingModal);
