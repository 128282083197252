import React from "react";

export default class TextImageViewWidget extends React.Component {
  static defaultProps = {
    config: {
      name: "",
      textBody: "",
      imageURL: "",
    },
  };

  render() {
    const { className, config, ...other } = this.props;
    const fullClassName = `dashboard-widget text-image ${className}`;

    const alt = config.name;
    const text = config.textBody;
    const src = config.imageURL;
    const {
      backgroundColor,
      textColor,
      opacity,
      textFontSize,
      textFontWeight,
      textFontStyle,
    } = config;

    console.log(config);

    const image = this.renderImage(src, alt);

    function containerStyle() {
      var style = { ...other.style };

      if (backgroundColor) {
        style = { ...style, backgroundColor: backgroundColor };
      }

      if (opacity) {
        style = { ...style, opacity: opacity / 100 };
      }

      return style;
    }

    function textStyle() {
      var style = {};

      if (textColor) {
        style = {
          ...style,
          color: textColor,
        };
      }

      if (textFontSize) {
        style = {
          ...style,
          fontSize: textFontSize,
        };
      }

      if (textFontWeight) {
        style = {
          ...style,
          fontWeight: textFontWeight,
        };
      }

      if (textFontStyle) {
        style = {
          ...style,
          fontStyle: textFontStyle,
        };
      }

      return style;
    }

    return (
      <div className={fullClassName} {...other} style={containerStyle()}>
        {image}
        <span className="text" style={textStyle()}>
          {text}
        </span>
      </div>
    );
  }

  renderImage(src, alt) {
    if (!src) {
      return null;
    }
    return <img className="image" src={src} alt={alt}></img>;
  }
}
