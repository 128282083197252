import React from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";
import { pollNowPlayingTrack } from "../actions";

class NowPlayingViewWidget extends React.Component {
  static defaultProps = {
    config: DashboardWidgetTypes.Types.NowPlaying.DefaultState,
  };

  componentDidMount() {
    const { pollNowPlayingTrack, config } = this.props;
    const feedUrl = config["feedUrl"] || "";
    pollNowPlayingTrack(feedUrl);
  }

  render() {
    const { track } = this.props;

    if (!track) {
      return null;
    }

    const {
      className,
      config,
      pollNowPlayingTrack,
      layout,
      ...other
    } = this.props;

    const fullClassName = `dashboard-widget now-playing ${className}`;

    const { title, artistName, albumName, imageURL } = track;

    const {
      showLogo,
      showStationName,
      showOnAir,
      transitionType,
      backgroundColor,
      onAirFontColor,
      stationFontColor,
      trackInfoFontColor,
      station,
      albumFontColor,
      opacity,
      onAirFontSize,
      onAirFontWeight,
      onAirFontStyle,
      stationFontSize,
      stationFontWeight,
      stationFontStyle,
      albumFontSize,
      albumFontWeight,
      albumFontStyle,
      trackInfoFontSize,
      trackInfoFontWeight,
      trackInfoFontStyle,
    } = config;

    function showItem(itemType) {
      return itemType ? { display: "inherit" } : { display: "none" };
    }

    function stationTextStyle() {
      var style = {
        fontSize: layout.w === 1 && layout.h === 1 ? "1vw" : "1.5vw",
      };

      if (stationFontColor) {
        style = {
          ...style,
          color: stationFontColor,
          textShadow: `0px 0px 10px ${stationFontColor}`,
        };
      }

      if (stationFontSize) {
        style = {
          ...style,
          fontSize: stationFontSize,
        };
      }

      if (stationFontWeight) {
        style = {
          ...style,
          fontWeight: stationFontWeight,
        };
      }

      if (stationFontStyle) {
        style = {
          ...style,
          fontStyle: stationFontStyle,
        };
      }

      return style;
    }

    function onAirTextStyle() {
      var style = {
        fontSize: layout.w === 1 && layout.h === 1 ? "1vw" : "1.75vw",
      };

      if (onAirFontColor) {
        style = {
          ...style,
          color: onAirFontColor,
          textShadow: `0px 0px 10px ${onAirFontColor}`,
        };
      }

      if (onAirFontSize) {
        style = {
          ...style,
          fontSize: onAirFontSize,
        };
      }

      if (onAirFontWeight) {
        style = {
          ...style,
          fontWeight: onAirFontWeight,
        };
      }

      if (onAirFontStyle) {
        style = {
          ...style,
          fontStyle: onAirFontStyle,
        };
      }

      return style;
    }

    function trackInfoTextStyle() {
      var style = {
        fontSize: layout.w === 1 && layout.h === 1 ? "0.75vw" : "1.0vw",
      };

      if (trackInfoFontColor) {
        style = {
          ...style,
          color: trackInfoFontColor,
        };
      }

      if (trackInfoFontSize) {
        style = {
          ...style,
          fontSize: trackInfoFontSize,
        };
      }

      if (trackInfoFontWeight) {
        style = {
          ...style,
          fontWeight: trackInfoFontWeight,
        };
      }

      if (trackInfoFontStyle) {
        style = {
          ...style,
          fontStyle: trackInfoFontStyle,
        };
      }

      return style;
    }

    function albumTextStyle() {
      var style = {
        fontSize: layout.w === 1 && layout.h === 1 ? "0.75vw" : "1.0vw",
      };

      if (albumFontColor) {
        style = {
          ...style,
          color: albumFontColor,
        };
      }

      if (albumFontSize) {
        style = {
          ...style,
          fontSize: albumFontSize,
        };
      }

      if (albumFontWeight) {
        style = {
          ...style,
          fontWeight: albumFontWeight,
        };
      }

      if (albumFontStyle) {
        style = {
          ...style,
          fontStyle: albumFontStyle,
        };
      }

      return style;
    }

    function visibleSections() {
      var sections = 1; // album artwork is always visible

      if (showLogo) {
        ++sections;
      }

      if (showStationName || showOnAir) {
        ++sections;
      }

      if (albumName || artistName) {
        ++sections;
      }

      return sections;
    }

    const horizontal = "horizontal";
    const vertical = "vertical";
    const square = "square";
    function aspect() {
      var aspect;

      if (layout.w > layout.h) {
        aspect = horizontal;
      } else if (layout.w < layout.h) {
        aspect = vertical;
      } else {
        aspect = square;
      }

      return aspect;
    }

    function nowPlayingContainerStyle() {
      var style = { ...other.style };

      if (backgroundColor) {
        style = { ...style, backgroundColor: backgroundColor };
      }

      if (opacity) {
        style = { ...style, opacity: opacity / 100 };
      }

      return style;
    }

    function nowPlayingContentStyle() {
      const widgetAspect = aspect();
      var style = { width: "33%", height: "100%" };

      if (widgetAspect === horizontal) {
        style = {
          ...style,
          width: visibleSections === 1 ? "0%" : `${100 / visibleSections()}%`,
        };
      } else if (widgetAspect === vertical) {
        style = {
          ...style,
          width: "100%",
          height: visibleSections === 1 ? "0%" : "20%",
        };
      }

      return style;
    }

    function albumContentStyle() {
      const widgetAspect = aspect();
      var style = {};

      if (widgetAspect === horizontal) {
        style = {
          ...style,
          width: `${100 / visibleSections()}%`,
        };
      } else if (widgetAspect === vertical) {
        style = {
          ...style,
          height: `${100 - (visibleSections() - 1) * 20}%`,
        };
      } else if (widgetAspect === square) {
        style = {
          ...style,
          width: "33%",
        };
      }

      return style;
    }

    function trackInfoContentStyle() {
      const widgetAspect = aspect();
      var style = nowPlayingContentStyle();

      if (widgetAspect === square) {
        style = {
          ...style,
          width: "75%",
          height: "25%",
          marginTop: "50%",
          alignSelf: "center",
        };
      }

      return style;
    }

    function artworkStyle() {
      var style = {
        maxWidth: albumContentStyle().width,
        maxHeight: albumContentStyle().height,
      };

      return style;
    }

    function renderAppleMusicRadio() {
      var image = {};

      switch (station) {
        case "1":
          image = {
            src: "/images/apple-music-radio-1.jpg",
            alt: "Apple Music Radio 1",
          };
          break;
        case "hits":
          image = {
            src: "/images/apple-music-radio-hits.jpg",
            alt: "Apple Music Radio Hits",
          };
          break;
        case "country":
          image = {
            src: "/images/apple-music-radio-country.jpg",
            alt: "Apple Music Radio Country",
          };
          break;
        default:
          // TODO: Get an apple music radio image for default case
          image = {
            src: "/images/apple-music-radio-1.jpg",
            alt: "Apple Music Radio",
          };
          break;
      }

      return (
        <div
          className="beats1 now-playing-content"
          style={{ ...nowPlayingContentStyle(), ...showItem(showLogo) }}
        >
          <img src={image.src} alt={image.alt} className="beats1-logo image" />
        </div>
      );
    }

    function renderStation() {
      return (
        <div
          className="beats1 now-playing-content"
          style={{
            ...nowPlayingContentStyle(),
            ...showItem(showStationName || showOnAir),
          }}
        >
          <div style={showItem(showStationName)}>
            <span className="station title" style={stationTextStyle()}>
              &#xF8FF;Music
            </span>
            <span
              className={`station title station-${station || "1"}`}
              style={stationTextStyle()}
            >
              &nbsp;Radio
            </span>
          </div>
          <div style={showItem(showOnAir)}>
            <span className="on-air title" style={onAirTextStyle()}>
              ON AIR
            </span>
          </div>
        </div>
      );
    }

    function renderAlbumArtwork() {
      return (
        <div
          className="album now-playing-content album-content"
          style={albumContentStyle()}
        >
          <TransitionGroup component={null}>
            <CSSTransition
              key={imageURL}
              classNames="now-playing-transition"
              timeout={{
                enter: 2000,
                exit: 2000,
              }}
            >
              <img
                src={imageURL}
                alt={imageURL}
                className={`artwork image ${transitionType}`}
                style={artworkStyle()}
              />
            </CSSTransition>
          </TransitionGroup>
        </div>
      );
    }

    function renderTrackInfo() {
      return (
        <div
          className={`now-playing now-playing-content ${
            albumName || artistName || title ? "uk-visible" : "uk-invisible"
          }`}
          style={{
            ...trackInfoContentStyle(),
          }}
        >
          <div
            className="marquee bold-title"
            style={showItem(albumName || artistName)}
          >
            {albumName && artistName ? (
              <span className="title" style={albumTextStyle()}>
                {albumName} &mdash; {artistName}
              </span>
            ) : (
              <span className="title" style={albumTextStyle()}>
                {albumName} {artistName}
              </span>
            )}
          </div>
          <div className="marquee" style={showItem(title)}>
            <span className="title" style={trackInfoTextStyle()}>
              {title}
            </span>
          </div>
        </div>
      );
    }

    function renderRectangle() {
      return (
        <div
          className={fullClassName}
          {...other}
          style={nowPlayingContainerStyle()}
        >
          <div className={`now-playing-container ${aspect()}`}>
            {renderAppleMusicRadio()}
            {renderStation()}
            {renderAlbumArtwork()}
            {renderTrackInfo()}
          </div>
        </div>
      );
    }

    function renderSquare() {
      return (
        <div
          className={fullClassName}
          {...other}
          style={nowPlayingContainerStyle()}
        >
          <div className={`now-playing-container ${aspect()}`}>
            {renderAppleMusicRadio()}
            {renderAlbumArtwork()}
            {renderStation()}
          </div>
          {renderTrackInfo()}
        </div>
      );
    }

    if (layout.h === layout.w) {
      return renderSquare();
    } else {
      return renderRectangle();
    }
  }
}

function mapStateToProps(state) {
  const { track } = state;
  return { track };
}

function mapDispatchToProps(dispatch) {
  return {
    pollNowPlayingTrack: (url) => dispatch(pollNowPlayingTrack(url)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NowPlayingViewWidget);
