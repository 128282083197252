import React from "react";
import { connect } from "react-redux";

import TextImageForm from "./TextImageForm";
import TwitterFeedWidgetForm from "./TwitterFeedWidgetForm";
import WorldClockForm from "./WorldClockForm";
import RadioScheduleForm from "./RadioScheduleForm";
import NowPlayingForm from "./NowPlayingForm";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

import { removeSubwidget } from "../actions";

class SubwidgetRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };
  }

  expand = () => {
    const { subwidgetKey, onExpand } = this.props;
    if (onExpand) {
      onExpand({ subwidgetKey: subwidgetKey, handleDone: this.handleDone, collapse: this.collapse });
    }
    this.setState({ isExpanded: true });
  };

  collapse = () => {
    const { subwidgetKey, onCollapse } = this.props;
    if (onCollapse) {
      onCollapse({ subwidgetKey: subwidgetKey });
    }
    this.setState({ isExpanded: false });
  };

  handleDelete = () => {
    const { removeSubwidget, subwidgetDataItem, subwidgetKey, onDelete } = this.props;
    if (onDelete) {
      onDelete({ subwidgetKey: subwidgetKey });
    }
    removeSubwidget(subwidgetDataItem);
  };

  handleDone = () => {
    if (this.submitForm) {
      this.submitForm();
    }
  };

  handleSubwidgetSubmit = (subwidgetDataItem, values) => {
    subwidgetDataItem.config = values;
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit({ collapse: this.collapse });
    } 
    else {
      this.collapse();
    }
  };

  handleSetSubmitForm = (submitForm) => {
    // ref to inner form for submit button
    this.submitForm = submitForm;
  };

  render() {
    const { subwidgetDataItem, style } = this.props;
    const { name, color, editable, src, alt } = this.widgetAttributes(
      subwidgetDataItem
    );

    const WidgetForm = this.selectWidgetForm(subwidgetDataItem);

    const { isExpanded } = this.state;
    if (isExpanded) {
      return (
        <div className="widget-socket uk-padding-small" style={style}>
          <div
            className="inner-socket full open uk-flex uk-flex-middle"
            style={{ backgroundColor: color }}
          >
            <img
              className="icon uk-margin-left uk-margin-right"
              src={src}
              alt={alt}
            />
            <div className="text uk-text uk-text-middle">{name}</div>
            <div
              className="icon uk-margin-right"
              uk-icon="chevron-up"
              onClick={this.handleDone}
            ></div>
          </div>

          <div className="form-container uk-padding-small">
            <WidgetForm
              subwidgetDataItem={subwidgetDataItem}
              setSubmitForm={this.handleSetSubmitForm}
              onSubmit={(values) =>
                this.handleSubwidgetSubmit(subwidgetDataItem, values)
              }
            />
          </div>
        </div>
      );
    }

    return (
      <div className="widget-socket uk-padding-small" style={style}>
        <div
          className="inner-socket full uk-flex uk-flex-middle"
          style={{ backgroundColor: color }}
        >
          <img
            className="icon uk-margin-left uk-margin-right"
            src={src}
            alt={alt}
          />
          <div className="text uk-text uk-text-middle">{name}</div>
          {editable ? (
            <div
              className="icon uk-margin-right"
              uk-icon="pencil"
              onClick={this.expand}
            ></div>
          ) : null}
          <div
            className="icon uk-margin-right"
            uk-icon="trash"
            onClick={this.handleDelete}
          ></div>
        </div>
      </div>
    );
  }

  widgetAttributes(subwidgetDataItem) {
    const { type } = subwidgetDataItem;
    const values = DashboardWidgetTypes.Types[type];

    return {
      name: values.Name,
      color: values.Color,
      editable: values.Editable,
      src: DashboardWidgetTypes.iconSrc(values.Stem),
      alt: DashboardWidgetTypes.iconAlt(values.Name),
    };
  }

  selectWidgetForm(subwidgetDataItem) {
    const { type } = subwidgetDataItem;

    if (type === DashboardWidgetTypes.Types.TextImage.Type) {
      return TextImageForm;
    }
    if (type === DashboardWidgetTypes.Types.TwitterFeed.Type) {
      return TwitterFeedWidgetForm;
    }
    if (type === DashboardWidgetTypes.Types.WorldClock.Type) {
      return WorldClockForm;
    }
    if (type === DashboardWidgetTypes.Types.RadioSchedule.Type) {
      return RadioScheduleForm;
    }
    if (type === DashboardWidgetTypes.Types.NowPlaying.Type) {
      return NowPlayingForm;
    }

    // only above two will end up displaying
    return React.Component;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    removeSubwidget: (subwidgetDataItem) =>
      dispatch(removeSubwidget(subwidgetDataItem)),
  };
}

export default connect(null, mapDispatchToProps)(SubwidgetRow);
