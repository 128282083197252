import React from "react";
import { Formik } from "formik";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class RadioScheduleForm extends React.Component {
  defaultState = DashboardWidgetTypes.Types.RadioSchedule.DefaultState;

  renderFeedURL = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label">Feed URL</label>
        <input
          className="uk-input"
          type="text"
          name={name}
          placeholder=""
          value={value || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    );
  };

  renderColor = (value, name, labelText, handleChange, handleBlur) => {
    return (
      <label className="uk-form-label-dark">
        <input
          className="uk-input uk-form-width-small uk-margin-small-right"
          type="color"
          name={name}
          placeholder=""
          value={value || "#000000"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {labelText}
      </label>
    );
  };

  renderOpacity = (value, name, labelText, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label-dark">
          <input
            className="slider uk-margin-small-right"
            type="range"
            name={name}
            min="0"
            max="100"
            value={value || 100}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {labelText} ({value || 100}%)
        </label>
      </div>
    );
  };

  renderFontWeight = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label-dark">
          <input
            className="uk-input uk-form-width-small uk-margin-small-right"
            type="number"
            name={name}
            placeholder=""
            min="100"
            max="1000"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          Font Weight
        </label>
      </div>
    );
  };

  renderFontSize = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label-dark">
          <input
            className="uk-input uk-form-width-small uk-margin-small-right"
            type="number"
            name={name}
            placeholder=""
            min="1"
            max="100"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          Font Size (px)
        </label>
      </div>
    );
  };

  renderFontStyle = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls uk-select-div">
        <label className="uk-form-label-dark">
          <select
            className="uk-select uk-form-width-small uk-margin-small-right"
            name={name}
            value={value || "normal"}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="italic">Italic</option>
            <option value="normal">Normal</option>
            <option value="oblique">Oblique</option>
            <option value="inherit">Inherit</option>
            <option value="initial">Initial</option>
            <option value="unset">Unset</option>
          </select>
          Font Style
        </label>
      </div>
    );
  };

  render() {
    const { subwidgetDataItem, setSubmitForm, onSubmit } = this.props;
    const config = this.normalizeConfig(subwidgetDataItem.config);

    return (
      <Formik
        initialValues={config}
        onSubmit={onSubmit}
        render={({ values, handleChange, handleBlur, submitForm }) => {
          setSubmitForm(submitForm);

          return (
            <div className="uk-form-stacked uk-margin">
              <div className="uk-margin">
                {this.renderFeedURL(
                  values.feedUrl,
                  "feedUrl",
                  handleChange,
                  handleBlur
                )}
              </div>
              
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.backgroundColor,
                    "backgroundColor",
                    "Background",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderOpacity(
                    values.opacity,
                    "opacity",
                    "Opacity",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-1">
                  <h4>Show Time</h4>
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.showTimeTextColor,
                    "showTimeTextColor",
                    "Font Color",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontWeight(
                    values.showTimeFontWeight,
                    "showTimeFontWeight",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderFontSize(
                    values.showTimeFontSize,
                    "showTimeFontSize",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontStyle(
                    values.showTimeFontStyle,
                    "showTimeFontStyle",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-1">
                  <h4>Show Name</h4>
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.showNameTextColor,
                    "showNameTextColor",
                    "Font Color",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontWeight(
                    values.showNameFontWeight,
                    "showNameFontWeight",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderFontSize(
                    values.showNameFontSize,
                    "showNameFontSize",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontStyle(
                    values.showNameFontStyle,
                    "showNameFontStyle",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-1">
                  <h4>Show Description</h4>
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.showDescriptionTextColor,
                    "showDescriptionTextColor",
                    "Font Color",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontWeight(
                    values.showDescriptionFontWeight,
                    "showDescriptionFontWeight",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderFontSize(
                    values.showDescriptionFontSize,
                    "showDescriptionFontSize",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontStyle(
                    values.showDescriptionFontStyle,
                    "showDescriptionFontStyle",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      feedUrl,
      backgroundColor,
      opacity,
      showTimeTextColor,
      showTimeFontSize,
      showTimeFontWeight,
      showTimeFontStyle,
      showNameTextColor,
      showNameFontSize,
      showNameFontWeight,
      showNameFontStyle,
      showDescriptionTextColor,
      showDescriptionFontSize,
      showDescriptionFontWeight,
      showDescriptionFontStyle,
    } = config || {};
    const normalized = {
      feedUrl,
      backgroundColor,
      opacity,
      showTimeTextColor,
      showTimeFontSize,
      showTimeFontWeight,
      showTimeFontStyle,
      showNameTextColor,
      showNameFontSize,
      showNameFontWeight,
      showNameFontStyle,
      showDescriptionTextColor,
      showDescriptionFontSize,
      showDescriptionFontWeight,
      showDescriptionFontStyle,
    };

    // undefined values will break controlled form inputs
    if (normalized.feedUrl === undefined) {
      normalized.feedUrl = this.defaultState.feedUrl;
    }

    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }

    if (normalized.opacity === undefined) {
      normalized.opacity = this.defaultState.opacity;
    }

    if (normalized.showTimeTextColor === undefined) {
      normalized.showTimeTextColor = this.defaultState.showTimeTextColor;
    }

    if (normalized.showTimeFontSize === undefined) {
      normalized.showTimeFontSize = this.defaultState.showTimeFontSize;
    }

    if (normalized.showTimeFontWeight === undefined) {
      normalized.showTimeFontWeight = this.defaultState.showTimeFontWeight;
    }

    if (normalized.showTimeFontStyle === undefined) {
      normalized.showTimeFontStyle = this.defaultState.showTimeFontStyle;
    }

    if (normalized.showNameTextColor === undefined) {
      normalized.showNameTextColor = this.defaultState.showNameTextColor;
    }

    if (normalized.showNameFontSize === undefined) {
      normalized.showNameFontSize = this.defaultState.showNameFontSize;
    }

    if (normalized.showNameFontWeight === undefined) {
      normalized.showNameFontWeight = this.defaultState.showNameFontWeight;
    }

    if (normalized.showNameFontStyle === undefined) {
      normalized.showNameFontStyle = this.defaultState.showNameFontStyle;
    }

    if (normalized.showDescriptionTextColor === undefined) {
      normalized.showDescriptionTextColor = this.defaultState.showDescriptionTextColor;
    }

    if (normalized.showDescriptionFontSize === undefined) {
      normalized.showDescriptionFontSize = this.defaultState.showDescriptionFontSize;
    }

    if (normalized.showDescriptionFontWeight === undefined) {
      normalized.showDescriptionFontWeight = this.defaultState.showDescriptionFontWeight;
    }

    if (normalized.showDescriptionFontStyle === undefined) {
      normalized.showDescriptionFontStyle = this.defaultState.showDescriptionFontStyle;
    }

    return normalized;
  }
}
