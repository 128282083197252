import React from "react";
import { Formik } from "formik";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";
import ImageUploadField from "./ImageUploadField";

export default class TextImageForm extends React.Component {
  defaultState = DashboardWidgetTypes.Types.TextImage.DefaultState;

  renderColor = (value, name, labelText, handleChange, handleBlur) => {
    return (
      <label className="uk-form-label-dark">
        <input
          className="uk-input uk-form-width-small uk-margin-small-right"
          type="color"
          name={name}
          placeholder=""
          value={value || "#000000"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {labelText}
      </label>
    );
  };

  renderFontWeight = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label-dark">
          <input
            className="uk-input uk-form-width-small uk-margin-small-right"
            type="number"
            name={name}
            placeholder=""
            min="100"
            max="1000"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          Font Weight
        </label>
      </div>
    );
  };

  renderFontSize = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label-dark">
          <input
            className="uk-input uk-form-width-small uk-margin-small-right"
            type="number"
            name={name}
            placeholder=""
            min="1"
            max="100"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          Font Size (px)
        </label>
      </div>
    );
  };

  renderFontStyle = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls uk-select-div">
        <label className="uk-form-label-dark">
          <select
            className="uk-select uk-form-width-small uk-margin-small-right"
            name={name}
            value={value || "normal"}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="italic">Italic</option>
            <option value="normal">Normal</option>
            <option value="oblique">Oblique</option>
            <option value="inherit">Inherit</option>
            <option value="initial">Initial</option>
            <option value="unset">Unset</option>
          </select>
          Font Style
        </label>
      </div>
    );
  };

  renderOpacity = (value, name, handleChange, handleBlur) => {
    return (
      <label className="uk-form-label-dark">
        <input
          className="slider uk-margin-small-right"
          type="range"
          name={name}
          min="0"
          max="100"
          value={value || 100}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        Opacity ({value || 100}%)
      </label>
    );
  };

  render() {
    const { subwidgetDataItem, setSubmitForm, onSubmit } = this.props;
    const config = this.normalizeConfig(subwidgetDataItem.config);

    return (
      <Formik
        initialValues={config}
        onSubmit={onSubmit}
        render={({ values, handleChange, handleBlur, submitForm }) => {
          // the back and forth of handlers required here to get
          // submission working is a little insane; sorry
          setSubmitForm(submitForm);

          return (
            <div className="uk-form-stacked uk-margin">
              <div className="uk-margin">
                <label className="uk-form-label-dark">
                  Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="name"
                    type="text"
                    placeholder=""
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label-dark">
                  Text Body
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="textBody"
                    type="text"
                    placeholder=""
                    value={values.textBody}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label-dark">Image</label>
                <ImageUploadField
                  name="imageURL"
                  value={values.imageURL}
                  label="Image"
                />
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.backgroundColor,
                    "backgroundColor",
                    "Background",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderOpacity(
                    values.opacity,
                    "opacity",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.textColor,
                    "textColor",
                    "Font Color",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontWeight(
                    values.textFontWeight,
                    "textFontWeight",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderFontSize(
                    values.textFontSize,
                    "textFontSize",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontStyle(
                    values.textFontStyle,
                    "textFontStyle",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      name,
      textBody,
      imageURL,
      backgroundColor,
      textColor,
      textFontSize,
      textFontWeight,
      textFontStyle,
      opacity,
    } = config || {};
    const normalized = {
      name,
      textBody,
      imageURL,
      backgroundColor,
      textColor,
      textFontSize,
      textFontWeight,
      textFontStyle,
      opacity,
    };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.textBody === undefined) {
      normalized.textBody = this.defaultState.textBody;
    }
    if (normalized.imageURL === undefined) {
      normalized.imageURL = this.defaultState.imageURL;
    }
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }
    if (normalized.textColor === undefined) {
      normalized.textColor = this.defaultState.textColor;
    }
    if (normalized.textFontSize === undefined) {
      normalized.textFontSize = this.defaultState.textFontSize;
    }
    if (normalized.textFontWeight === undefined) {
      normalized.textFontWeight = this.defaultState.textFontWeight;
    }
    if (normalized.textFontStyle === undefined) {
      normalized.textFontStyle = this.defaultState.textFontStyle;
    }
    if (normalized.opacity === undefined) {
      normalized.opacity = this.defaultState.opacity;
    }

    return normalized;
  }
}
