import React from "react";
import { connect } from "react-redux";
import UIkit from "uikit";
import moment from "moment";

import { Formik } from "formik";
import FormikDatetime from "./FormikDatetime";

import { saveTimeSlot, destroyTimeSlot } from "../actions";

class TimeSlotForm extends React.Component {
  handleSubmit = (values, { setSubmitting, resetForm }) => {
    const { timeSlot, saveTimeSlot } = this.props;
    timeSlot.set(values);

    saveTimeSlot(timeSlot, {
      onDone: () => {
        setSubmitting(false);
        resetForm();
      },
    });
  };

  handleDelete = () => {
    const { destroyTimeSlot, timeSlot } = this.props;
    UIkit.modal.confirm("Are you sure you want to delete this Time Slot?").then(
      function () {
        destroyTimeSlot(timeSlot);
        UIkit.notification(
          "<span uk-icon='icon: check'></span> Time Slot Deleted",
          {
            status: "success",
            timeout: 5000,
          }
        );
      },
      function () {}
    );
  };

  onStartAtChanged = ({ updatedValue, maxValue, field, form }) => {
    console.log("onStartAtChanged");

    const now = moment();
    const value = moment.max(now, updatedValue);
    form.setFieldValue(field.name, value);
    if (value > maxValue) {
      form.setFieldValue("end_at", value);
    }
  };

  onEndAtChanged = ({ updatedValue, minValue, field, form }) => {
    console.log("onEndAtChanged");

    const now = moment();
    const value = moment.max(now, updatedValue);
    form.setFieldValue(field.name, value);
    if (value < minValue) {
      form.setFieldValue("start_at", value);
    }
  };

  render() {
    const { timeSlot, dashboard } = this.props;
    if (!timeSlot || !dashboard) {
      return null;
    }

    const initialValues = timeSlot.attributes;
    initialValues.start_at = new Date(initialValues.start_at);
    initialValues.end_at = new Date(initialValues.end_at);

    try {
      initialValues.layout = initialValues.layout.get("id");
    } catch (_) {
      // already converted to id
    }

    const layouts = dashboard.get("layouts");

    return (
      <Formik
        initialValues={initialValues}
        validate={() => {}}
        onSubmit={this.handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.layout &&
            errors.layout &&
            touched.recurring &&
            errors.recurring &&
            touched.start_at &&
            errors.start_at &&
            touched.end_at &&
            errors.end_at;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-modal-body uk-overflow-visible">
                <div className="uk-margin">
                  <label className="uk-form-label">Name</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="name"
                      type="text"
                      placeholder="Name of the time slot"
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div
                  className="uk-margin uk-grid"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <div className="uk-form-controls uk-width-1-2">
                    <label className="uk-form-label">Start Time</label>
                  </div>
                  <div className="uk-form-controls uk-width-1-2">
                    <label className="uk-form-label">End Time</label>
                  </div>
                </div>

                <div
                  className="uk-margin uk-grid"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <div className="uk-form-controls uk-width-1-2">
                    <FormikDatetime
                      name="start_at"
                      value={values.start_at}
                      maxValue={values.end_at}
                      disablePastDates={true}
                      onDateChange={this.onStartAtChanged}
                    />
                  </div>
                  <div className="uk-form-controls uk-width-1-2">
                    <FormikDatetime
                      name="end_at"
                      value={values.end_at}
                      minValue={values.start_at}
                      disablePastDates={true}
                      onDateChange={this.onEndAtChanged}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Layout</label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      name="layout"
                      value={values.layout || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {layouts.map((layout) => (
                        <option key={layout.get("id")} value={layout.get("id")}>
                          {layout.getDisplayName()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="uk-margin-small">
                  <div className="uk-form-controls">
                    <label className="uk-form-label">
                      <input
                        className="uk-checkbox uk-margin-small-right"
                        type="checkbox"
                        name="recurring"
                        checked={values.recurring || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Weekly Recurrence
                    </label>
                  </div>
                </div>

                {error ? (
                  <div className="uk-margin uk-form-danger">{error}</div>
                ) : null}

                <div className="uk-margin uk-flex uk-flex-between">
                  <button
                    className="tm-button tm-button-link-caution uk-modal-close"
                    type="button"
                    onClick={() => resetForm(initialValues)}
                  >
                    Cancel
                  </button>
                  <div className="uk-flex uk-flex-right uk-flex-middle">
                    {timeSlot.isNew() ? null : (
                      <button
                        className="tm-button tm-button-caution"
                        type="button"
                        onClick={this.handleDelete}
                      >
                        Delete
                      </button>
                    )}
                    <button
                      className="tm-button tm-button-primary uk-margin-left"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveTimeSlot: (timeSlot) => dispatch(saveTimeSlot(timeSlot)),
    destroyTimeSlot: (timeSlot) => dispatch(destroyTimeSlot(timeSlot)),
  };
}

export default connect(null, mapDispatchToProps)(TimeSlotForm);
