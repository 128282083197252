import React, { Children } from "react";
import { connect } from "react-redux";
import BigCalendar from "react-big-calendar";

import _ from "lodash";
import moment from "moment";

import TimeSlotModel from "../models/TimeSlotModel";
import { loadTimeSlots, editTimeSlot } from "../actions";

function now() {
  return moment();
}

function startOfToday() {
  return moment().startOf("day");
}

function tomorrow() {
  return moment().startOf("day").add(1, "days");
}

function yesterday() {
  return moment().startOf("day").subtract(1, "days");
}

const DateCellWrapper = ({ children, value }) => {
  var { className } = { ...children.props };

  if (moment(value).isBefore(startOfToday())) {
    className = className.concat(" ", "rbc-disabled");
  } else {
    className = className.concat(" ", "rbc-enabled");
  }

  return React.cloneElement(Children.only(children), {
    className: className,
  });
};

const TimeSlotWrapper = ({ children, value }) => {
  var { className } = { ...children.props };

  if (children.props.children === undefined) {
    if (moment(value).isBefore(now())) {
      className = className.concat(" ", "rbc-disabled");
    } else {
      className = className.concat(" ", "rbc-enabled");
    }
  } else if (children.props.children === null) {
    className = className.concat(" ", "rbc-hidden");
  }

  return React.cloneElement(Children.only(children), {
    className: className,
  });
};

class DashboardSchedule extends React.Component {
  static localizer = BigCalendar.momentLocalizer(moment);

  // display several years worth of weekly recurrence
  static recurrenceDisplayCount = 7 * 52;

  componentWillMount() {
    const { dashboard, loadTimeSlots } = this.props;
    loadTimeSlots(dashboard);
  }

  handleSelectSlot = async ({ start, end }) => {
    const { dashboard, editTimeSlot } = this.props;
    if (moment(start).isAfter(now()) && start !== end) {
      // a time slot was selected
      const timeSlot = await TimeSlotModel.fromDashboard(dashboard, {
        start,
        end,
      });
      editTimeSlot(timeSlot);
    } else if (moment(start).isAfter(yesterday()) && start === end) {
      // a day slot was selected
      end = moment(end).endOf("day").toDate();
      const timeSlot = await TimeSlotModel.fromDashboard(dashboard, {
        start,
        end,
      });
      editTimeSlot(timeSlot);
    }
  };

  handleSelectEvent = (event) => {
    const { editTimeSlot } = this.props;
    editTimeSlot(event.timeSlot);
  };

  render() {
    const { timeSlots } = this.props;
    if (!timeSlots) {
      return null;
    }

    const events = this.generateAllEvents(timeSlots);

    return (
      <div className="vl-calendar-container">
        <BigCalendar
          localizer={this.constructor.localizer}
          views={{ week: true, month: true }}
          defaultView="week"
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable={true}
          min={tomorrow().toDate()}
          onSelectSlot={this.handleSelectSlot}
          onSelectEvent={this.handleSelectEvent}
          components={{
            dateCellWrapper: DateCellWrapper,
            timeSlotWrapper: TimeSlotWrapper,
          }}
        />
      </div>
    );
  }

  generateAllEvents(timeSlots) {
    return _.flatten(
      timeSlots.map((timeSlot) => this.generateEvents(timeSlot))
    );
  }

  generateEvents(timeSlot) {
    if (timeSlot.get("recurring")) {
      const array = Array(this.constructor.recurrenceDisplayCount).fill();
      return array.map((_, i) => this.generateEvent(timeSlot, i));
    }
    return [this.generateEvent(timeSlot)];
  }

  generateEvent(timeSlot, weekOffset = 0) {
    return {
      start: this.weeksLater(new Date(timeSlot.get("start_at")), weekOffset),
      end: this.weeksLater(new Date(timeSlot.get("end_at")), weekOffset),
      title: timeSlot.get("name"),
      timeSlot,
    };
  }

  weeksLater(date, weeks) {
    const later = new Date();
    later.setTime(date.getTime() + weeks * 7 * 24 * 60 * 60 * 1000);
    return later;
  }
}

function mapStateToProps(state) {
  const { timeSlots } = state;
  return { timeSlots };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTimeSlots: (dashboard) => dispatch(loadTimeSlots(dashboard)),
    editTimeSlot: (timeSlot) => dispatch(editTimeSlot(timeSlot)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSchedule);
