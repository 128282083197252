import React from "react";
// import Draggable from "react-draggable";

// Wrap around widgets in toolbar tray to make draggable.
export default class ToolbarWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = { originalPosition: undefined };
  }

  static defaultProps = {
    enabled: true    
  }

  onStart = (_event, data) => {
    const { x, y } = data;
    this.setState({ originalPosition: { x, y } });
  };

  onStop = (event, _data) => {
    const position = this.extractEventPosition(event);
    const { type } = this.props;

    const droppedEvent = new CustomEvent("vl-toolbar-widget-dropped", {
      detail: { position, type },
    });
    document.body.dispatchEvent(droppedEvent);
  };

  onClick = (_event, _data) => {
    const position = { x: 1, y: 1 };
    const { type } = this.props;
    const wasClicked = true;

    const droppedEvent = new CustomEvent("vl-toolbar-widget-dropped", {
      detail: { position, type, wasClicked },
    });
    document.body.dispatchEvent(droppedEvent);
  };

  render() {
    const { children, enabled } = this.props;
    return <div onClick={this.onClick} disabled={!enabled}>{children}</div>;
  }

  extractEventPosition(event) {
    let { pageX, pageY } = event;
    if (pageX && pageY) {
      return { x: pageX, y: pageY };
    }

    // TouchEvent (mobile, but also Chrome development tools responsive view)
    const { changedTouches } = event;
    if (!changedTouches || changedTouches.length === 0) {
      // should never happen
      return { x: 0, y: 0 };
    }

    const changedTouch = changedTouches[changedTouches.length - 1];
    return { x: changedTouch.pageX, y: changedTouch.pageY };
  }
}
