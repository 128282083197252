import React from "react";
import { connect } from "react-redux";

import Modal from "./Modal";

class ErrorModal extends React.Component {
  render() {
    const { error } = this.props;
    if (!error) {
      return <Modal modalId="error-modal" />;
    }

    const message = error.message || error;
    return (
      <Modal modalId="error-modal" title="Error!">
        <div className="uk-modal-body" style={{ whiteSpace: "break-spaces" }}>
          {message}
        </div>
        <div className="uk-modal-footer ">
          <button
            className="tm-button tm-button-primary uk-modal-close uk-float-right"
            type="button"
          >
            OK
          </button>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { error } = state;
  return { error };
}

export default connect(mapStateToProps)(ErrorModal);
