import React from "react";
import { connect } from "react-redux";
import { ActionCableProvider } from "react-actioncable-provider";

import InstagramCommentsConsumer from "./InstagramCommentsConsumer";

// Manage ActionCable consumers for Instagram comments. The library is a little
// buggy, so we want to just mount this once and leave it mounted.
//
// Note there's only ever one Instagram consumer, which receives events for all
// Instagram accounts with the Instagram app installed. This should only ever
// be the Apple Music Radio account.
class InstagramCommentsConsumerManager extends React.Component {
  render() {
    return (
      <ActionCableProvider url={process.env.REACT_APP_WS_URL}>
        <InstagramCommentsConsumer />
      </ActionCableProvider>
    );
  }
}

function mapStateToProps(_state) {
  return {};
}

export default connect(mapStateToProps)(InstagramCommentsConsumerManager);
