import React from "react";

import LayoutEditorCanvas from "./LayoutEditorCanvas";
import WidgetToolbar from "./WidgetToolbar";

export default class LayoutEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = { toolbarEnabled: true };
  }

  onLayoutChanged = ({ slotsAvailable }) => {
    this.setState({ toolbarEnabled: slotsAvailable });
  }

  render() {
    return (
      <div style={{ overflow: "scroll" }}>
        <div className="layout-editor-container">
          <div className="layout-editor-canvas-container">
            <h5>Layout</h5>
            <LayoutEditorCanvas onLayoutChanged={this.onLayoutChanged} />
          </div>
          <div className="widget-toolbar-container">
            <h5>Widgets</h5>
            <WidgetToolbar enabled={this.state.toolbarEnabled} />
          </div>
        </div>
      </div>
    );
  }
}
