import React from "react";
import { connect } from "react-redux";
import { ActionCableConsumer } from "react-actioncable-provider";

import moment from "moment";

import { setTwitterFeedTweets } from "../actions";

class TwitterFeedConsumer extends React.Component {
  componentWillMount() {
    const {
      twitterFeedTweets,
      twitterFeedID,
      setTwitterFeedTweets,
    } = this.props;

    if ((twitterFeedTweets || {})[twitterFeedID]) return;

    setTwitterFeedTweets(twitterFeedID, []);
  }

  handleReceived = (message) => {
    // left for dev convenience
    // console.log(JSON.stringify(message, null, 2));

    const tweet = this.extractDisplayTweet(message);
    const {
      twitterFeedTweets,
      twitterFeedID,
      setTwitterFeedTweets,
    } = this.props;

    const tweets = (twitterFeedTweets || {})[twitterFeedID] || [];
    if (tweets.find((t) => t.twitter_id === tweet.twitter_id)) {
      return;
    }
    tweets.unshift(tweet);

    tweets.sort((a, b) => {
      if (a.created_at === b.created_at) {
        // avoid flipping sort order around when created_at matches
        return a.str_id > b.str_id ? 1 : -1;
      }
      return moment(a.created_at) - moment(b.created_at);
    });
    tweets.reverse();

    // left for dev convenience
    // console.log(JSON.stringify(tweets, null, 2))

    const maxTweets = 20;
    setTwitterFeedTweets(twitterFeedID, tweets.slice(0, maxTweets));
  };

  render() {
    const { twitterFeedID } = this.props;

    // HACK: Work around bug in ActionCableConsumer where remounting disconnects cable.
    return (this.consumer = this.consumer || (
      <ActionCableConsumer
        channel={{ channel: "TwitterFeedChannel", id: twitterFeedID }}
        onReceived={this.handleReceived}
      />
    ));
  }

  extractDisplayTweet(message) {
    const {
      twitter_id,
      user,
      created_at,
      text,
      favorite_count,
      retweet_count,
      in_reply_to_screen_name,
      retweeted_status,
      quoted_status,
      extended_entities,
      place,
    } = message;

    const username = (user && user.name) || "";
    const user_screen_name = (user && user.screen_name) || "";
    const user_verified = (user && user.verified) || false;

    const place_name = place && (place.full_name || place.name);

    return {
      twitter_id,
      username,
      user_screen_name,
      user_verified,
      created_at,
      text,
      favorite_count,
      retweet_count,
      in_reply_to_screen_name,
      retweeted_status: retweeted_status
        ? this.extractDisplayTweet(retweeted_status)
        : null,
      quoted_status: quoted_status
        ? this.extractDisplayTweet(quoted_status)
        : null,
      extended_entities,
      place_name,
    };
  }
}

function mapStateToProps(state) {
  const { twitterFeedTweets } = state;
  return { twitterFeedTweets };
}

function mapDispatchToProps(dispatch) {
  return {
    setTwitterFeedTweets: (twitterFeedID, tweets) =>
      dispatch(setTwitterFeedTweets(twitterFeedID, tweets)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterFeedConsumer);
