import {
  LOAD_MODEL_DEFAULTS_SUCCESS,
  LOAD_MODEL_DEFAULTS_ERROR,
  LOAD_DASHBOARDS_SUCCESS,
  LOAD_DASHBOARDS_ERROR,
  LOAD_DASHBOARD_SUCCESS,
  LOAD_DASHBOARD_ERROR,
  SAVE_DASHBOARD_SUCCESS,
  SAVE_DASHBOARD_ERROR,
  DESTROY_DASHBOARD_SUCCESS,
  DESTROY_DASHBOARD_ERROR,
  LOAD_LAYOUT_SUCCESS,
  LOAD_LAYOUT_ERROR,
  SAVE_LAYOUT_SUCCESS,
  SAVE_LAYOUT_ERROR,
  DESTROY_LAYOUT_SUCCESS,
  DESTROY_LAYOUT_ERROR,
  LOAD_TIME_SLOTS_SUCCESS,
  LOAD_TIME_SLOTS_ERROR,
  LOAD_THEME_SUCCESS,
  LOAD_THEME_ERROR,
  LOAD_THEMES_SUCCESS,
  LOAD_THEMES_ERROR,
  SAVE_THEME_ERROR,
  SAVE_THEME_SUCCESS,
  DESTROY_THEME_SUCCESS,
  DESTROY_THEME_ERROR,
  EDIT_WIDGET,
  CLEAR_WIDGET,
  ADD_SUBWIDGET,
  REMOVE_SUBWIDGET,
  SCHEDULE_TICKED,
  EDIT_TIME_SLOT,
  CLEAR_TIME_SLOT,
  SAVE_TIME_SLOT_SUCCESS,
  SAVE_TIME_SLOT_ERROR,
  LOAD_TWITTER_FEEDS_SUCCESS,
  LOAD_TWITTER_FEED_SUCCESS,
  LOAD_TWITTER_FEED_ERROR,
  SAVE_TWITTER_FEED_SUCCESS,
  DESTROY_TWITTER_FEED_SUCCESS,
  SUBSCRIBE_TWITTER_FEED_SUCCESS,
  SUBSCRIBE_TWITTER_FEED_ERROR,
  SET_TWITTER_FEED_TWEETS,
  LOAD_TWITTER_LISTS_SUCCESS,
  LOAD_TWITTER_LISTS_ERROR,
  LOAD_TWITTER_GLOBAL_FILTER_SUCCESS,
  LOAD_TWITTER_GLOBAL_FILTER_ERROR,
  SAVE_TWITTER_GLOBAL_FILTER_SUCCESS,
  SAVE_TWITTER_GLOBAL_FILTER_ERROR,
  LOAD_YOUTUBE_STREAMER_EMAILS_SUCCESS,
  LOAD_YOUTUBE_STREAMER_EMAILS_ERROR,
  SUBSCRIBE_YOUTUBE_LIVE_CHAT,
  SET_YOUTUBE_LIVE_CHAT_MESSAGES,
  SET_INSTAGRAM_COMMENTS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_ERROR,
  DESTROY_USER_ERROR,
  GRANT_USER_ADMIN_ERROR,
  REVOKE_USER_ADMIN_ERROR,
  SET_BREADCRUMB_ITEMS,
  CLEAR_MODAL,
  POLLED_NOW_PLAYING_TRACK,
  POLLED_UPCOMING_SHOWS,
  POLLED_TWITTER_ANALYTICS_SUCCESS,
  POLLED_TWITTER_ANALYTICS_ERROR,
  debug,
} from "./actions";

import DashboardWidgetTypes from "./lib/DashboardWidgetTypes";

function dashboardApp(state = {}, action) {
  debug({ actionReducer: action.type, action });
  switch (action.type) {
    case LOAD_MODEL_DEFAULTS_SUCCESS:
      return {
        ...state,
        dashboardDefaults: action.dashboardDefaults,
        themeDefaults: action.themeDefaults,
      };

    case LOAD_DASHBOARDS_SUCCESS:
      return { ...state, dashboards: action.dashboards };

    case LOAD_DASHBOARD_SUCCESS:
      return { ...state, dashboard: action.dashboard };

    case SAVE_DASHBOARD_SUCCESS:
      return { ...state, dashboard: action.dashboard };

    case DESTROY_DASHBOARD_SUCCESS:
      return state;

    case LOAD_LAYOUT_SUCCESS:
      return { ...state, layout: action.layout };

    case SAVE_LAYOUT_SUCCESS:
      return { ...state, layout: action.layout };

    case DESTROY_LAYOUT_SUCCESS:
      return state;

    case EDIT_WIDGET:
      return {
        ...state,
        layout: action.layout,
        index: action.index,
        widgetDataItem: action.widgetDataItem,
        subwidgetData: action.subwidgetData,
        showModalId: DashboardWidgetTypes.baseModalId(
          action.widgetDataItem.type
        ),
      };

    case CLEAR_WIDGET:
      return {
        ...state,
        index: undefined,
        widgetDataItem: undefined,
        subwidgetData: undefined,
        showModalId: undefined,
      };

    case ADD_SUBWIDGET:
      let subwidgetData = state.subwidgetData || [];
      return {
        ...state,
        subwidgetData: [...subwidgetData, action.subwidgetDataItem],
      };

    case REMOVE_SUBWIDGET:
      return {
        ...state,
        subwidgetData: state.subwidgetData.filter(
          (subwidgetDataItem) => subwidgetDataItem !== action.subwidgetDataItem
        ),
      };

    case SCHEDULE_TICKED:
      return { ...state, scheduledLayout: action.scheduledLayout };

    case EDIT_TIME_SLOT:
      return {
        ...state,
        timeSlot: action.timeSlot,
        showModalId: "time-slot-modal",
      };

    case CLEAR_TIME_SLOT:
      return {
        ...state,
        timeSlot: undefined,
        showModalId: undefined,
      };

    case LOAD_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: [...action.timeSlots] };

    case SAVE_TIME_SLOT_SUCCESS:
      return { ...state, timeSlot: action.timeSlot };

    case SAVE_THEME_SUCCESS:
      return { ...state, theme: action.theme, themeError: undefined };

    case LOAD_THEME_SUCCESS:
      return { ...state, theme: action.theme };

    case LOAD_THEMES_SUCCESS:
      return { ...state, themes: action.themes };

    case DESTROY_THEME_SUCCESS:
      return state;

    case LOAD_TWITTER_FEEDS_SUCCESS:
      return { ...state, twitterFeeds: action.twitterFeeds };

    case LOAD_TWITTER_FEED_SUCCESS:
      return { ...state, twitterFeed: action.twitterFeed };

    case SAVE_TWITTER_FEED_SUCCESS:
      return { ...state, twitterFeed: action.twitterFeed };

    case DESTROY_TWITTER_FEED_SUCCESS:
      return state;

    case SUBSCRIBE_TWITTER_FEED_SUCCESS:
      return {
        ...state,
        subscribedTwitterFeeds: [
          ...new Set([
            ...(state.subscribedTwitterFeeds || []),
            action.twitterFeed,
          ]),
        ].sort(),
      };

    case SET_TWITTER_FEED_TWEETS:
      return {
        ...state,
        twitterFeedTweets: {
          ...(state.twitterFeedTweets || {}),
          [action.twitterFeedID]: [...action.tweets],
        },
      };

    case LOAD_TWITTER_LISTS_SUCCESS:
      return { ...state, twitterLists: action.twitterLists };

    case LOAD_TWITTER_GLOBAL_FILTER_SUCCESS:
    case SAVE_TWITTER_GLOBAL_FILTER_SUCCESS:
      return { ...state, twitterGlobalFilter: action.twitterGlobalFilter };

    case LOAD_YOUTUBE_STREAMER_EMAILS_SUCCESS:
      return { ...state, youtubeStreamerEmails: action.youtubeStreamerEmails };

    case SUBSCRIBE_YOUTUBE_LIVE_CHAT:
      return {
        ...state,
        subscribedYouTubeStreamerEmails: [
          ...new Set([
            ...(state.subscribedYouTubeStreamerEmails || []),
            action.streamerEmail,
          ]),
        ].sort(),
      };

    case SET_YOUTUBE_LIVE_CHAT_MESSAGES:
      return {
        ...state,
        youtubeLiveChatMessages: {
          ...(state.youtubeLiveChatMessages || {}),
          [action.streamerEmail]: [...action.chatMessages],
        },
      };

    case SET_INSTAGRAM_COMMENTS:
      return {
        ...state,
        instagramComments: [...action.instagramComments],
      };

    case LOAD_USERS_SUCCESS:
      return { ...state, users: action.users };

    case SET_BREADCRUMB_ITEMS:
      return { ...state, breadcrumbItems: action.data };

    case CLEAR_MODAL:
      return { ...state, showModalId: undefined };

    case POLLED_NOW_PLAYING_TRACK:
      return { ...state, track: action.track };

    case POLLED_UPCOMING_SHOWS:
      return { ...state, upcomingShows: action.upcomingShows };

    case POLLED_TWITTER_ANALYTICS_SUCCESS:
      return {
        ...state,
        twitterAnalytics: action.twitterAnalytics,
        twitterAnalyticsError: undefined,
      };

    case POLLED_TWITTER_ANALYTICS_ERROR:
      return {
        ...state,
        twitterAnalytics: undefined,
        twitterAnalyticsError: "Failed to fetch Twitter analytics!",
      };

    case LOAD_MODEL_DEFAULTS_ERROR:
    case LOAD_DASHBOARDS_ERROR:
    case SAVE_DASHBOARD_ERROR:
    case DESTROY_DASHBOARD_ERROR:
    case SAVE_LAYOUT_ERROR:
    case DESTROY_LAYOUT_ERROR:
    case LOAD_TIME_SLOTS_ERROR:
    case SAVE_TIME_SLOT_ERROR:
    case SUBSCRIBE_TWITTER_FEED_ERROR:
    case LOAD_TWITTER_LISTS_ERROR:
    case LOAD_TWITTER_GLOBAL_FILTER_ERROR:
    case SAVE_TWITTER_GLOBAL_FILTER_ERROR:
    case LOAD_THEMES_ERROR:
    case DESTROY_THEME_ERROR:
    case LOAD_YOUTUBE_STREAMER_EMAILS_ERROR:
    case LOAD_USERS_ERROR:
    case DESTROY_USER_ERROR:
    case GRANT_USER_ADMIN_ERROR:
    case REVOKE_USER_ADMIN_ERROR:
      return { ...state, showModalId: "error-modal", error: action.error };

    case LOAD_LAYOUT_ERROR:
    case LOAD_DASHBOARD_ERROR:
    case LOAD_THEME_ERROR:
    case LOAD_TWITTER_FEED_ERROR:
      return { ...state, showModalId: action.error.code === 404 ? "" : "error-modal", error: action.error };

    case SAVE_THEME_ERROR:
      return { ...state, themeError: action.error };

    default:
      return state;
  }
}
export default dashboardApp;
