import AppNavigator from "../../pages/AppNavigator";

export default class DashboardsNavigator extends AppNavigator {
  pushIndex = () => this.push(this.indexPath());

  pushNew = () => this.push(this.newPath());

  pushEdit = (dashboard, hashParam) =>
    this.push(this.editPath(dashboard, hashParam));

  pushViewInTab = (dashboard) =>
    window.open(this.viewPath(dashboard), "_blank");

  indexPath = () => "/dashboards";

  newPath = () => "/dashboards/new";

  editPath = (dashboard, hashParam) => {
    if (typeof(hashParam) !== 'string' && !(hashParam instanceof String)) {
      // handle event being passed as argument when this is callback
      hashParam = null;
    }
    return hashParam ? `/dashboards/${dashboard.get("id")}/${hashParam}` : `/dashboards/${dashboard.get("id")}`;
  };

  viewPath = (dashboard) => `/dashboards/${dashboard.get("id")}/view`;
}
