import React from "react";
import { connect } from "react-redux";
import TimezonePicker from "react-timezone";

import WidgetModal from "./WidgetModal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

class WorldClockModal extends React.Component {
  defaultState = DashboardWidgetTypes.Types.WorldClock.DefaultState;

  renderColor = (value, name, labelText, handleChange, handleBlur) => {
    return (
      <label className="uk-form-label">
        <input
          className="uk-input uk-form-width-small uk-margin-small-right"
          type="color"
          name={name}
          placeholder=""
          value={value || "#000000"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {labelText}
      </label>
    );
  };

  renderFontWeight = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label">
          <input
            className="uk-input uk-form-width-small uk-margin-small-right"
            type="number"
            name={name}
            placeholder=""
            min="100"
            max="1000"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          Font Weight
        </label>
      </div>
    );
  };

  renderFontSize = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label">
          <input
            className="uk-input uk-form-width-small uk-margin-small-right"
            type="number"
            name={name}
            placeholder=""
            min="1"
            max="100"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          Font Size (px)
        </label>
      </div>
    );
  };

  renderFontStyle = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls uk-select-div">
        <label className="uk-form-label">
          <select
            className="uk-select uk-form-width-small uk-margin-small-right"
            name={name}
            value={value || "normal"}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="italic">Italic</option>
            <option value="normal">Normal</option>
            <option value="oblique">Oblique</option>
            <option value="inherit">Inherit</option>
            <option value="initial">Initial</option>
            <option value="unset">Unset</option>
          </select>
          Font Style
        </label>
      </div>
    );
  };

  renderOpacity = (value, name, handleChange, handleBlur) => {
    return (
      <label className="uk-form-label">
        <input
          className="slider uk-margin-small-right"
          type="range"
          name={name}
          min="0"
          max="100"
          value={value || 100}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        Opacity ({value || 100}%)
      </label>
    );
  };

  render() {
    const { widgetDataItem } = this.props;
    if (!widgetDataItem) {
      return <WidgetModal modalId="world-clock-modal" />;
    }
    const config = this.normalizeConfig(widgetDataItem.config);

    return (
      <WidgetModal
        modalId="world-clock-modal"
        title="Edit World Clock Widget"
        initialValues={config}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.timezone &&
            errors.timezone &&
            touched.isTwelveHour &&
            errors.isTwelveHour &&
            touched.isSecondsDisplayed &&
            errors.isSecondsDisplayed &&
            touched.timezoneText &&
            errors.timezoneText &&
            touched.backgroundColor &&
            errors.backgroundColor &&
            touched.textColor &&
            errors.textColor &&
            touched.textFontSize &&
            errors.textFontSize &&
            touched.textFontWeight &&
            errors.textFontWeight &&
            touched.textFontStyle &&
            errors.textFontStyle &&
            touched.opacity &&
            errors.opacity;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-modal-body" uk-overflow-auto="">
                <div className="uk-margin">
                  <label className="uk-form-label">Name</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type="text"
                      name="name"
                      placeholder="Name of the widget"
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Timezone</label>
                  <div className="uk-form-controls">
                    <TimezonePicker
                      offset="UTC"
                      className="timezone-picker"
                      inputProps={{
                        placeholder: "Timezone",
                        name: "timezone",
                      }}
                      value={values.timezone}
                      onChange={(value) => setFieldValue("timezone", value)}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Custom Timezone Text</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type="text"
                      name="timezoneText"
                      placeholder=""
                      value={values.timezoneText || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div
                  className="uk-margin uk-grid"
                  style={{ alignItems: "center" }}
                >
                  <div className="uk-form-controls uk-width-1-2">
                    <label className="uk-form-label">
                      <input
                        className="uk-checkbox uk-margin-small-right"
                        type="checkbox"
                        name="isTwelveHour"
                        checked={values.isTwelveHour || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Display 12-hour format
                    </label>
                  </div>

                  <div className="uk-form-controls uk-width-1-2">
                    <label className="uk-form-label">
                      <input
                        className="uk-checkbox uk-margin-small-right"
                        type="checkbox"
                        name="isSecondsDisplayed"
                        checked={values.isSecondsDisplayed || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Display seconds
                    </label>
                  </div>
                </div>

                <div
                  className="uk-margin uk-grid"
                  style={{ alignItems: "center" }}
                >
                  <div class="uk-width-1-2">
                    {this.renderColor(
                      values.backgroundColor,
                      "backgroundColor",
                      "Background",
                      handleChange,
                      handleBlur
                    )}
                  </div>
                  <div class="uk-width-1-2">
                    {this.renderOpacity(
                      values.opacity,
                      "opacity",
                      handleChange,
                      handleBlur
                    )}
                  </div>
                </div>

                <div
                  className="uk-margin uk-grid"
                  style={{ alignItems: "center" }}
                >
                  <div class="uk-width-1-2">
                    {this.renderColor(
                      values.textColor,
                      "textColor",
                      "Font Color",
                      handleChange,
                      handleBlur
                    )}
                  </div>
                  <div class="uk-width-1-2">
                    {this.renderFontWeight(
                      values.textFontWeight,
                      "textFontWeight",
                      handleChange,
                      handleBlur
                    )}
                  </div>
                </div>

                <div
                  className="uk-margin uk-grid"
                  style={{ alignItems: "center" }}
                >
                  <div class="uk-width-1-2">
                    {this.renderFontSize(
                      values.textFontSize,
                      "textFontSize",
                      handleChange,
                      handleBlur
                    )}
                  </div>
                  <div class="uk-width-1-2">
                    {this.renderFontStyle(
                      values.textFontStyle,
                      "textFontStyle",
                      handleChange,
                      handleBlur
                    )}
                  </div>

                  <div class="uk-width-1-1 uk-modal-header" />
                </div>

                {error ? (
                  <div className="uk-margin uk-form-danger">{error}</div>
                ) : null}

                <div className="uk-margin uk-flex uk-flex-between">
                  <button
                    className="tm-button tm-button-link-caution uk-modal-close"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="tm-button tm-button-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      name,
      timezone,
      isTwelveHour,
      isSecondsDisplayed,
      timezoneText,
      backgroundColor,
      textColor,
      textFontSize,
      textFontWeight,
      textFontStyle,
      opacity,
    } = config || {};
    const normalized = {
      name,
      timezone,
      isTwelveHour,
      isSecondsDisplayed,
      timezoneText,
      backgroundColor,
      textColor,
      textFontSize,
      textFontWeight,
      textFontStyle,
      opacity,
    };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }

    if (
      normalized.timezoneText === undefined ||
      (typeof normalized.timezoneText === "string" &&
        normalized.timezoneText.trim() === "")
    ) {
      normalized.timezoneText = this.defaultState.timezoneText;
    }

    if (normalized.timezone === undefined) {
      normalized.timezone = this.defaultState.timezone;
    }

    if ([undefined, ""].includes(normalized.isTwelveHour)) {
      normalized.isTwelveHour = this.defaultState.isTwelveHour;
    }

    if ([undefined, ""].includes(normalized.isSecondsDisplayed)) {
      normalized.isSecondsDisplayed = this.defaultState.isSecondsDisplayed;
    }

    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }

    if (normalized.textColor === undefined) {
      normalized.textColor = this.defaultState.textColor;
    }

    if (normalized.textFontSize === undefined) {
      normalized.textFontSize = this.defaultState.textFontSize;
    }

    if (normalized.textFontWeight === undefined) {
      normalized.textFontWeight = this.defaultState.textFontWeight;
    }

    if (normalized.textFontStyle === undefined) {
      normalized.textFontStyle = this.defaultState.textFontStyle;
    }

    if (normalized.opacity === undefined) {
      normalized.opacity = this.defaultState.opacity;
    }

    return normalized;
  }
}

function mapStateToProps(state) {
  const { widgetDataItem } = state;
  return { widgetDataItem };
}

export default connect(mapStateToProps)(WorldClockModal);
