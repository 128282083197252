import React from "react";
import { Formik } from "formik";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class NowPlayingForm extends React.Component {
  defaultState = DashboardWidgetTypes.Types.NowPlaying.DefaultState;

  renderFeedURL = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label">Feed URL</label>
        <input
          className="uk-input"
          type="text"
          name={name}
          placeholder=""
          value={value || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    );
  };

  renderShow = (value, name, labelText, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label-dark">
          <input
            className="uk-checkbox uk-margin-small-right"
            type="checkbox"
            name={name}
            checked={value || false}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {labelText}
        </label>
      </div>
    );
  };

  renderTransitionType = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls uk-select-div">
        <label className="uk-form-label-dark">
          <select
            className="uk-select uk-form-width-small uk-margin-small-right"
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="none">None</option>
            <option value="fade">Fade</option>
            <option value="flip">Flip</option>
            <option value="slide">Slide</option>
          </select>
          Transition Type
        </label>
      </div>
    );
  };

  renderColor = (value, name, labelText, handleChange, handleBlur) => {
    return (
      <label className="uk-form-label-dark">
        <input
          className="uk-input uk-form-width-small uk-margin-small-right"
          type="color"
          name={name}
          placeholder=""
          value={value || "#000000"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {labelText}
      </label>
    );
  };

  renderFontWeight = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label-dark">
          <input
            className="uk-input uk-form-width-small uk-margin-small-right"
            type="number"
            name={name}
            placeholder=""
            min="100"
            max="1000"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          Font Weight
        </label>
      </div>
    );
  };

  renderFontSize = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls">
        <label className="uk-form-label-dark">
          <input
            className="uk-input uk-form-width-small uk-margin-small-right"
            type="number"
            name={name}
            placeholder=""
            min="1"
            max="100"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          Font Size (px)
        </label>
      </div>
    );
  };

  renderFontStyle = (value, name, handleChange, handleBlur) => {
    return (
      <div className="uk-form-controls uk-select-div">
        <label className="uk-form-label-dark">
          <select
            className="uk-select uk-form-width-small uk-margin-small-right"
            name={name}
            value={value || "normal"}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="italic">Italic</option>
            <option value="normal">Normal</option>
            <option value="oblique">Oblique</option>
            <option value="inherit">Inherit</option>
            <option value="initial">Initial</option>
            <option value="unset">Unset</option>
          </select>
          Font Style
        </label>
      </div>
    );
  };

  render() {
    const { subwidgetDataItem, setSubmitForm, onSubmit } = this.props;
    const config = this.normalizeConfig(subwidgetDataItem.config);

    return (
      <Formik
        initialValues={config}
        onSubmit={onSubmit}
        render={({ values, handleChange, handleBlur, submitForm }) => {
          setSubmitForm(submitForm);

          return (
            <div className="uk-form-stacked uk-margin">
              <div className="uk-margin">
                <label className="uk-form-label-dark">Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type="text"
                    name="name"
                    placeholder=""
                    value={values.name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Station</label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    name="station"
                    value={values.station}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="1">1</option>
                    <option value="hits">Hits</option>
                    <option value="country">Country</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                {this.renderFeedURL(
                  values.feedUrl,
                  "feedUrl",
                  handleChange,
                  handleBlur
                )}
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderTransitionType(
                    values.transitionType,
                    "transitionType",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderShow(
                    values.showLogo,
                    "showLogo",
                    "Show Logo",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.backgroundColor,
                    "backgroundColor",
                    "Background",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  <label className="uk-form-label-dark">
                    <input
                      className="slider uk-margin-small-right"
                      type="range"
                      name="opacity"
                      min="0"
                      max="100"
                      value={values.opacity || 100}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Opacity ({values.opacity || 100}%)
                  </label>
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  <h4>Station</h4>
                </div>
                <div class="uk-width-1-2">
                  {this.renderShow(
                    values.showStationName,
                    "showStationName",
                    "Show",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.stationFontColor,
                    "stationFontColor",
                    "Font Color",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontWeight(
                    values.stationFontWeight,
                    "stationFontWeight",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderFontSize(
                    values.stationFontSize,
                    "stationFontSize",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontStyle(
                    values.stationFontStyle,
                    "stationFontStyle",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  <h4>On Air</h4>
                </div>
                <div class="uk-width-1-2">
                  {this.renderShow(
                    values.showOnAir,
                    "showOnAir",
                    "Show",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.onAirFontColor,
                    "onAirFontColor",
                    "Font Color",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontWeight(
                    values.onAirFontWeight,
                    "onAirFontWeight",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderFontSize(
                    values.onAirFontSize,
                    "onAirFontSize",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontStyle(
                    values.onAirFontStyle,
                    "onAirFontStyle",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  <h4>Album/Artist Info</h4>
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.albumFontColor,
                    "albumFontColor",
                    "Font Color",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontWeight(
                    values.albumFontWeight,
                    "albumFontWeight",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderFontSize(
                    values.albumFontSize,
                    "albumFontSize",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontStyle(
                    values.albumFontStyle,
                    "albumFontStyle",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>

              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  <h4>Track Info</h4>
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderColor(
                    values.trackInfoFontColor,
                    "trackInfoFontColor",
                    "Font Color",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontWeight(
                    values.trackInfoFontWeight,
                    "trackInfoFontWeight",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
              <div
                className="uk-margin uk-grid"
                style={{ alignItems: "center" }}
              >
                <div class="uk-width-1-2">
                  {this.renderFontSize(
                    values.trackInfoFontSize,
                    "trackInfoFontSize",
                    handleChange,
                    handleBlur
                  )}
                </div>
                <div class="uk-width-1-2">
                  {this.renderFontStyle(
                    values.trackInfoFontStyle,
                    "trackInfoFontStyle",
                    handleChange,
                    handleBlur
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      name,
      feedUrl,
      showLogo,
      showStationName,
      showOnAir,
      transitionType,
      backgroundColor,
      onAirFontColor,
      stationFontColor,
      trackInfoFontColor,
      station,
      albumFontColor,
      opacity,
      onAirFontSize,
      onAirFontWeight,
      onAirFontStyle,
      stationFontSize,
      stationFontWeight,
      stationFontStyle,
      albumFontSize,
      albumFontWeight,
      albumFontStyle,
      trackInfoFontSize,
      trackInfoFontWeight,
      trackInfoFontStyle,
    } = config || {};
    const normalized = {
      name,
      feedUrl,
      showLogo,
      showStationName,
      showOnAir,
      transitionType,
      backgroundColor,
      onAirFontColor,
      stationFontColor,
      trackInfoFontColor,
      station,
      albumFontColor,
      opacity,
      onAirFontSize,
      onAirFontWeight,
      onAirFontStyle,
      stationFontSize,
      stationFontWeight,
      stationFontStyle,
      albumFontSize,
      albumFontWeight,
      albumFontStyle,
      trackInfoFontSize,
      trackInfoFontWeight,
      trackInfoFontStyle,
    };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.feedUrl === undefined) {
      normalized.feedUrl = this.defaultState.feedUrl;
    }
    if (normalized.showLogo === undefined || normalized.showLogo === "") {
      normalized.showLogo = this.defaultState.showLogo;
    }
    if (
      normalized.showStationName === undefined ||
      normalized.showStationName === ""
    ) {
      normalized.showStationName = this.defaultState.showStationName;
    }
    if (normalized.showOnAir === undefined || normalized.showOnAir === "") {
      normalized.showOnAir = this.defaultState.showOnAir;
    }
    if (
      normalized.transitionType === undefined ||
      normalized.transitionType === ""
    ) {
      normalized.transitionType = this.defaultState.transitionType;
    }
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }
    if (normalized.onAirFontColor === undefined) {
      normalized.onAirFontColor = this.defaultState.onAirFontColor;
    }
    if (normalized.stationFontColor === undefined) {
      normalized.stationFontColor = this.defaultState.stationFontColor;
    }
    if (normalized.trackInfoFontColor === undefined) {
      normalized.trackInfoFontColor = this.defaultState.trackInfoFontColor;
    }
    if (normalized.station === undefined) {
      normalized.station = this.defaultState.station;
    }
    if (normalized.opacity === undefined) {
      normalized.opacity = this.defaultState.opacity;
    }
    if (normalized.albumFontColor === undefined) {
      normalized.albumFontColor = this.defaultState.albumFontColor;
    }
    if (normalized.onAirFontSize === undefined) {
      normalized.onAirFontSize = this.defaultState.onAirFontSize;
    }
    if (normalized.onAirFontWeight === undefined) {
      normalized.onAirFontWeight = this.defaultState.onAirFontWeight;
    }
    if (normalized.onAirFontStyle === undefined) {
      normalized.onAirFontStyle = this.defaultState.onAirFontStyle;
    }
    if (normalized.stationFontSize === undefined) {
      normalized.stationFontSize = this.defaultState.stationFontSize;
    }
    if (normalized.stationFontWeight === undefined) {
      normalized.stationFontWeight = this.defaultState.stationFontWeight;
    }
    if (normalized.stationFontStyle === undefined) {
      normalized.stationFontStyle = this.defaultState.stationFontStyle;
    }
    if (normalized.albumFontSize === undefined) {
      normalized.albumFontSize = this.defaultState.albumFontSize;
    }
    if (normalized.albumFontWeight === undefined) {
      normalized.albumFontWeight = this.defaultState.albumFontWeight;
    }
    if (normalized.albumFontStyle === undefined) {
      normalized.albumFontStyle = this.defaultState.albumFontStyle;
    }
    if (normalized.trackInfoFontSize === undefined) {
      normalized.trackInfoFontSize = this.defaultState.trackInfoFontSize;
    }
    if (normalized.trackInfoFontWeight === undefined) {
      normalized.trackInfoFontWeight = this.defaultState.trackInfoFontWeight;
    }
    if (normalized.trackInfoFontStyle === undefined) {
      normalized.trackInfoFontStyle = this.defaultState.trackInfoFontStyle;
    }

    return normalized;
  }
}
