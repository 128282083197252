import React from "react";
import { Formik, Field } from "formik";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import MasterClientNavigator from "../pages/MasterClientNavigator";

class LayoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  componentDidUpdate(previousProps) {
    const previousId = previousProps.layout && previousProps.layout.get("id");
    const currentId = this.props.layout && this.props.layout.get("id");

    if (previousId !== currentId) {
      if (this.formik.current) {
        this.formik.current.resetForm();
      }
    }
  }

  handleCancel = () => {
    const { dashboard } = this.props;

    if (document.getElementById("dashboardLayoutTabs")) {
      UIkit.tab("#dashboardLayoutTabs").show(0);
    } else {
      this.navigator.pushDashboardEdit(dashboard);
    }
  };

  render() {
    const { layout, onSubmit } = this.props;
    if (!layout) {
      return null;
    }

    const { name, description } = layout.attributes;
    const initialValues = { name, description };

    return (
      <Formik
        ref={this.formik}
        initialValues={initialValues}
        validate={() => {}}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.description &&
            errors.description;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              {error ? (
                <div className="uk-margin uk-form-danger">{error}</div>
              ) : null}

              <div className="uk-margin">
                <label className="uk-form-label">
                  Name <span className="vl-required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="name"
                    type="text"
                    placeholder=""
                    value={values.name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    autoFocus={true}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Description</label>
                <div className="uk-form-controls">
                  <Field
                    component="textarea"
                    className="uk-textarea"
                    name="description"
                    type="text"
                    placeholder=""
                    value={values.description || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                  />
                </div>
              </div>

              <hr />

              <div className="uk-margin uk-flex uk-flex-between">
                <button
                  className="tm-button tm-button-link-caution"
                  type="button"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="tm-button tm-button-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default withRouter(LayoutForm);
