import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import AbstractBasePage from "../../pages/AbstractBasePage";
import { DashboardsNavigator } from "./Bundle";

import DashboardForm from "../../components/DashboardForm";
import PageTitle from "../../components/PageTitle";
import { BreadcrumbItem } from "../../components/Breadcrumbs";

import DashboardModel from "../../models/DashboardModel";

import { setBreadcrumbItems, saveDashboard } from "../../actions";

class DashboardsNew extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new DashboardsNavigator(this.props.history);
  }

  handleSubmit = (data, { setSubmitting }) => {
    const dashboard = DashboardModel.findOrCreate(data);

    const self = this;
    this.props.saveDashboard(dashboard, {
      onSuccess: () => {
        setSubmitting(false);
        self.navigator.pushIndex();
        UIkit.notification(
          "<span uk-icon='icon: check'></span> Dashboard Created",
          {
            status: "success",
            timeout: 5000,
          }
        );
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  render() {
    const { dashboardDefaults } = this.props;
    if (!dashboardDefaults) {
      return null;
    }

    return (
      <div>
        <PageTitle text="New Dashboard" />
        <DashboardForm
          dashboard={new DashboardModel(dashboardDefaults.attributes)}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }

  getBreadcrumbItems() {
    const pushIndex = this.navigator.pushIndex;
    return [
      new BreadcrumbItem("Dashboards", pushIndex),
      new BreadcrumbItem("New Dashboard"),
    ];
  }
}

function mapStateToProps(state) {
  const { breadcrumbItems, dashboardDefaults } = state;
  return { breadcrumbItems, dashboardDefaults };
}

function mapDispatchToProps(dispatch) {
  return {
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
    saveDashboard: (dashboard, { onSuccess, onError }) =>
      dispatch(saveDashboard(dashboard, { onSuccess, onError })),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardsNew)
);
