export default class AppNavigator {
  constructor(history) {
    this.history = history;
  }

  pushRoot = () => this.push(this.rootPath());

  rootPath = () => '/';

  push = (pathname = '') => {
    window.history.pushState({}, '', window.location.href);
    window.location.replace(pathname);
  }
}
