import React from "react";
import { connect } from "react-redux";

import WidgetModal from "./WidgetModal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

import { loadYouTubeStreamerEmails } from "../actions";

class YouTubeLiveChatModal extends React.Component {
  defaultState = DashboardWidgetTypes.Types.YouTubeLiveChat.DefaultState;

  componentWillMount() {
    this.props.loadYouTubeStreamerEmails();
  }

  render() {
    const { widgetDataItem, youtubeStreamerEmails } = this.props;
    if (!widgetDataItem || !youtubeStreamerEmails) {
      return <WidgetModal modalId="youtube-live-chat-modal" />;
    }
    const config = this.normalizeConfig(widgetDataItem.config);

    if (config.streamerEmail === "" && youtubeStreamerEmails.length > 0) {
      config.streamerEmail = youtubeStreamerEmails[0];
    }

    return (
      <WidgetModal
        modalId="youtube-live-chat-modal"
        title="Edit YouTube Live Chat Widget"
        initialValues={config}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.streamerEmail &&
            errors.streamerEmail;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-modal-body">
                <div className="uk-margin">
                  <label className="uk-form-label">Name</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="name"
                      type="text"
                      placeholder="Name of the widget"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                {youtubeStreamerEmails && youtubeStreamerEmails.length > 0 ? (
                  <div className="uk-margin">
                    <label className="uk-form-label">
                      Linked YouTube account
                    </label>
                    <div className="uk-form-controls">
                      <select
                        className="uk-select"
                        name="streamerEmail"
                        value={values.streamerEmail || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {youtubeStreamerEmails.map((streamerEmail) => (
                          <option key={streamerEmail} value={streamerEmail}>
                            {streamerEmail}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="uk-alert uk-alert-info">
                    <p>
                      You don't have any linked YouTube accounts! Link an
                      account using the button at the top right of this site.
                    </p>
                  </div>
                )}

                {error ? (
                  <div className="uk-margin uk-form-danger">{error}</div>
                ) : null}

                <div className="uk-margin uk-flex uk-flex-between">
                  <button
                    className="tm-button tm-button-link-caution uk-modal-close"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="tm-button tm-button-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const { name, streamerEmail } = config || {};
    const normalized = { name, streamerEmail };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.streamerEmail === undefined) {
      normalized.streamerEmail = this.defaultState.streamerEmail;
    }

    return normalized;
  }
}

function mapStateToProps(state) {
  const { widgetDataItem, youtubeStreamerEmails } = state;
  return { widgetDataItem, youtubeStreamerEmails };
}

function mapDispatchToProps(dispatch) {
  return {
    loadYouTubeStreamerEmails: () => dispatch(loadYouTubeStreamerEmails()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YouTubeLiveChatModal);
