import React from "react";
import { connect } from "react-redux";
import RGL, { WidthProvider } from "react-grid-layout";

import _ from "lodash";

import FocusListener from "../lib/FocusListener";
import Utils from "../lib/Utils";

import SelectingDashboardWidget from "./SelectingDashboardWidget";

import { saveLayout, editWidget } from "../actions";

const ReactGridLayout = WidthProvider(RGL);
const rows = 4;
const columns = 6;
const infinity = 100000;

class LayoutEditorCanvas extends React.Component {
  static defaultProps = {
    className: "layout",
    rowHeight: 120,
    cols: 6,
    rows: 4,
    autoSize: false,
    compactType: null,
    preventCollision: true,
  };

  constructor(props) {
    super(props);
    this.state = { isWidthReady: false };
  }

  async componentDidMount() {
    this._isMounted = true;

    document.body.addEventListener(
      "vl-toolbar-widget-dropped",
      this.handleToolbarWidgetDropped
    );

    const listener = FocusListener.get();
    listener.addTabFocusGainedCallback(this.handleTabFocusGained);

    const { layout } = this.props;
    this.reportLayoutChanged(layout);

    // give page time to size
    await Utils.sleep(200);

    const isWidthReady = listener.isTabFocused();
    if (this._isMounted) {
      this.setState({ isWidthReady });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    document.body.removeEventListener(
      "vl-toolbar-widget-dropped",
      this.handleToolbarWidgetDropped
    );

    FocusListener.get().removeTabFocusGainedCallback(this.handleTabFocusGained);
  }

  render() {
    const { layout, gridLayout } = this.props;
    const { isWidthReady } = this.state;
    if (!layout || !gridLayout || !isWidthReady) {
      return null;
    }

    return (
      <div className="layout-editor-canvas-background">
        <ReactGridLayout
          {...this.props}
          className="layout-editor-canvas"
          layout={gridLayout}
          onLayoutChange={this.handleLayoutChange}
          onDrag={this.handleDrag}
          onResize={this.handleResize}
          onDragStop={this.handleDragStop}
        >
          {this.generateDOM()}
        </ReactGridLayout>
      </div>
    );
  }

  // private

  handleLayoutChange = (gridLayout) => {
    const { layout, widgetData, saveLayout } = this.props;

    // avoid saving on every mouse click
    if (layout.isLike(gridLayout, widgetData)) return;

    layout.set("grid_layout", gridLayout);
    layout.set("widget_data", widgetData);
    saveLayout(layout);
    this.reportLayoutChanged(layout);
  };

  handleTabFocusGained = () => {
    this.setState({ isWidthReady: true });
  };

  handleToolbarWidgetDropped = (event) => {
    const { position, type, wasClicked } = event.detail;

    // id does not get forwarded properly by grid lib, so use class name
    const elements = document.getElementsByClassName("layout-editor-canvas");
    for (const element of elements) {
      const rect = this.getBoundingDocumentRect(element);
      if (this.isInRect(position, rect) || wasClicked) {
        this.pushWidget(type);
      }
    }
  };

  handleResize = (_layout, oldItem, newItem, _placeholder, _e, _element) => {
    newItem.maxH = this.props.rows - oldItem.y;
  };

  handleDrag = (_layout, oldItem, _newItem, _placeholder, _e, element) => {
    const transformation = element.style.transform.match(/(-?[0-9.]+)/g);
    const newTransformation = [transformation[0], transformation[1]];
    let shouldTransform = false;
    const maxX = 660 - (oldItem.w - 1) * 130; // TODO: possibly figure out dynamically?
    const maxY = 400 - (oldItem.h - 1) * 130;
    const layoutPadding = 10;

    // drag too far left
    if (transformation[0] < layoutPadding) {
      newTransformation[0] = layoutPadding;
      shouldTransform = true;
    }

    // drag too far right
    if (transformation[0] > maxX) {
      newTransformation[0] = maxX;
      shouldTransform = true;
    }

    // drag too far up
    if (transformation[1] < layoutPadding) {
      newTransformation[1] = layoutPadding;
      shouldTransform = true;
    }

    // drag too far down
    if (transformation[1] > maxY) {
      newTransformation[1] = maxY;
      shouldTransform = true;
    }

    // stay in bounds
    if (shouldTransform) {
      element.style.transform = `translate(${newTransformation[0]}px, ${newTransformation[1]}px)`;
    }
  };

  handleDragStop = (_layout, oldItem, newItem, _placeholder, _e, _element) => {
    const { rows } = this.props;

    // if new item is below max rows, move it back
    if (newItem.y + newItem.h > rows) {
      newItem.y = oldItem.y;
      newItem.x = oldItem.x;
    }
  };

  handleEditWidget = (index) => {
    const { layout } = this.props;
    this.props.editWidget(layout, index);
  };

  handleDeleteWidget = (index) => {
    const { layout, gridLayout, widgetData, saveLayout } = this.props;

    layout.set(
      "grid_layout",
      gridLayout.filter((item) => item.i !== index)
    );
    layout.set("widget_data", _.omit(widgetData, index));

    saveLayout(layout);
    this.reportLayoutChanged(layout);
  };

  generateDOM() {
    const { gridLayout, widgetData } = this.props;

    return _.compact(
      gridLayout.map((item) => {
        const widgetDataItem = widgetData[item.i];
        if (!widgetDataItem) {
          // should only happen in development by accident
          console.log(`Missing widget data item for index=${item.i}!`);
          return null;
        }

        const { type } = widgetDataItem;
        return (
          <SelectingDashboardWidget
            key={item.i}
            index={item.i}
            type={type}
            onEdit={this.handleEditWidget}
            onDelete={this.handleDeleteWidget}
          />
        );
      })
    );
  }

  pushWidget(type) {
    const { layout, gridLayout, widgetData, saveLayout } = this.props;

    const i = this.nextIndex();
    const { x, y } = this.nextPosition();

    if (y !== infinity) {
      layout.set(
        "grid_layout",
        gridLayout.concat({
          i,
          w: 1,
          h: 1,
          x,
          y,
        })
      );
      layout.set("widget_data", { [i]: { type }, ...widgetData });

      saveLayout(layout);
      this.reportLayoutChanged(layout);
    }
  }

  // getBoundingClientRect except relative to document, not viewport.
  getBoundingDocumentRect(element) {
    let {
      x,
      y,
      width,
      height,
      left,
      right,
      top,
      bottom,
    } = element.getBoundingClientRect();
    return {
      x: x + window.scrollX,
      y: y + window.scrollY,
      width,
      height,
      left: left + window.scrollX,
      right: right + window.scrollX,
      top: top + window.scrollY,
      bottom: bottom + window.scrollY,
    };
  }

  isInRect(position, rect) {
    return (
      position.x >= rect.left &&
      position.x <= rect.right &&
      position.y >= rect.top &&
      position.y <= rect.bottom
    );
  }

  nextIndex() {
    return (parseInt(this.maxIndex()) + 1).toString();
  }

  maxIndex() {
    const { gridLayout } = this.props;
    if (!gridLayout || gridLayout.length === 0) {
      return "-1";
    }

    const indexes = gridLayout.map((item) => parseInt(item.i));
    return Math.max(...indexes).toString();
  }

  // Find first free position in grid.
  nextPosition() {
    const { gridLayout } = this.props;
    if (!gridLayout || gridLayout.length === 0) {
      return { x: 0, y: 0 };
    }

    // representation of which grid positions are taken
    const grid = new Array(columns);
    for (let x = 0; x < columns; x++) {
      grid[x] = new Array(rows).fill(false);
    }

    // iterate layout items and mark positions as taken
    for (const item of gridLayout) {
      for (let x = item.x; x < item.x + item.w; x++) {
        for (let y = item.y; y < item.y + item.h; y++) {
          grid[x][y] = true;
        }
      }
    }

    // find first free position (left to right, top to bottom)
    for (let y = 0; y < rows; y++) {
      for (let x = 0; x < columns; x++) {
        if (!grid[x][y]) {
          return { x, y };
        }
      }
    }

    // no free positions; place to left at bottom
    return { x: 0, y: infinity };
  }

  totalSlots() {
    return columns * rows;
  }

  remainingGridSlots(gridLayout) {
    const occupiedSlots = _.chain(gridLayout)
      .filter(function ({ y }) {
        return y < columns;
      })
      .reduce(function (sum, {w, h}) {
        return sum + (w * h);
      }, 0)
      .value();
    const remainingSlots = this.totalSlots() - occupiedSlots;
    return remainingSlots;
  }

  reportLayoutChanged(layout) {
    const { onLayoutChanged } = this.props;
    const gridLayout = layout.get("grid_layout");
    const remainingSlots = this.remainingGridSlots(gridLayout);
    onLayoutChanged({
      slotsAvailable: remainingSlots > 0,
    });
  }
}

function mapStateToProps(state) {
  const { layout } = state;
  const gridLayout = layout.get("grid_layout");
  const widgetData = layout.get("widget_data");
  return { layout, gridLayout, widgetData };
}

function mapDispatchToProps(dispatch) {
  return {
    editWidget: (layout, index) => dispatch(editWidget(layout, index)),
    saveLayout: (layout) => dispatch(saveLayout(layout)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutEditorCanvas);
