import React from "react";
import { connect } from "react-redux";
import UIkit from "uikit";

import DashboardRow from "./DashboardRow";

import { loadDashboards, destroyDashboard } from "../actions";

class DashboardTable extends React.Component {
  render() {
    const { dashboards, destroyDashboard } = this.props;
    if (!dashboards) {
      return null;
    }

    return (
      <div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-middle uk-margin">
          <thead>
            <tr>
              <th>Name</th>
              <th>Layout Count</th>
              <th>Created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dashboards.map((dashboard) => (
              <DashboardRow
                key={dashboard.get("id")}
                dashboard={dashboard}
                onDelete={() => destroyDashboard(dashboard)}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const dashboards = state.dashboards || [];
  return { dashboards };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDashboards: () => dispatch(loadDashboards()),
    destroyDashboard: (dashboard) => {
      UIkit.modal
        .confirm("Are you sure you want to delete this Dashboard?")
        .then(
          function () {
            dispatch(destroyDashboard(dashboard));
            UIkit.notification(
              "<span uk-icon='icon: check'></span> Dashboard Deleted",
              {
                status: "success",
                timeout: 5000,
              }
            );
          },
          function () {}
        );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTable);
