import React from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import MasterClientNavigator from "../pages/MasterClientNavigator";
import { withRouter } from "react-router-dom";

class TwitterGlobalFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  handleCancel = () => {
    this.navigator.pushTwitterFeedIndex();
  };

  render() {
    const { twitterGlobalFilter, onSubmit } = this.props;
    if (!twitterGlobalFilter || !onSubmit) {
      return null;
    }

    const commaSeparate = (strings) =>
      strings
        .map((s) => s.trim())
        .filter((s) => s !== "")
        .join(", ");
    const initialValues = {
      id: twitterGlobalFilter.id,
      blocked_topics: commaSeparate(twitterGlobalFilter.blocked_topics),
      blocked_follows: commaSeparate(twitterGlobalFilter.blocked_follows),
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-margin">
                <label className="uk-form-label">
                  Blocked feed topic keywords
                </label>
                <div className="uk-form-controls">
                  <Field
                    component="textarea"
                    className="uk-textarea"
                    name="blocked_topics"
                    type="text"
                    placeholder="A comma separated list of topics that should not be shown"
                    value={values.blocked_topics || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Blocked screen names</label>
                <div className="uk-form-controls">
                  <Field
                    component="textarea"
                    className="uk-textarea"
                    name="blocked_follows"
                    type="text"
                    placeholder="A comma separated list of screen names that should not be shown"
                    value={values.blocked_follows || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                  />
                </div>
              </div>

              <hr />
              <div className="uk-margin uk-flex uk-flex-between">
                <button
                  className="tm-button tm-button-link-caution"
                  type="button"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="tm-button tm-button-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

function mapStateToProps(_state) {
  return {};
}

function mapDispatchToProps(_dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TwitterGlobalFilterForm)
);
